import React, {
  useEffect,
  useRef,
  useState,
  Dispatch,
  SetStateAction,
} from "react";
import CustomInputField from "../../../../components/CustomHTMLElements/EmbedCustomInputField";
import styles from "./Withdraw.module.scss";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupValidators } from "../../../../helpers/yupValidators";
import { Bank } from "../../../../interfaces/banks";
import useFetchBanks from "../../../../custom-hooks/useFetchBanks";
import user from "../../../../assets/web/user.svg";
import CustomTextArea from "../../../../components/CustomHTMLElements/CustomTextArea";
import Loader from "../../../../components/Loader/Loader";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { postData } from "../../../../apis/apiMethods";
import { FormData } from "./Withdraw";
import { Alert, Button } from "antd";
import { appInsights } from "../../../../components/AppInsight/AppInsight";

const schema = yup.object().shape({
  amount: yupValidators.amount,
  bankName: yupValidators.bankName,
  accountNumber: yupValidators.accountNumber,
  description: yupValidators.description,
});

interface WithDrawalStepOneProps {
  setCurrentStep: Dispatch<SetStateAction<number>>;
  walletDetails: any;
  setBankCode: Dispatch<SetStateAction<string>>;
  bankCode: string;
  setDetails: Dispatch<SetStateAction<FormData>>;
  details: FormData;
  bankList: Bank[];
  setIsLoadingCustomerName: Dispatch<SetStateAction<boolean>>;
  isLoadingCustomerName: boolean;
  customerName: any;
  setCustomerName: Dispatch<any>;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
}

function WithdrawalStepOne({
  setCurrentStep,
  walletDetails,
  bankCode,
  setDetails,
  details,
  setBankCode,
  bankList,
  setIsLoadingCustomerName,
  isLoadingCustomerName,
  customerName,
  setCustomerName,
  error,
  setError,
  }: WithDrawalStepOneProps) {
    const [filteredBankList, setFilteredBankList] = useState<Bank[]>([]);
    const [showDropdown, setShowDropdown] = useState<boolean>(false);
    const [formattedValue, setFormattedValue] = useState("");
    const [withdrawalError, setWithdrawalError] = useState("");

    const divRef = useRef<HTMLDivElement>(null);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });
    const data = localStorage.getItem("currentUser");
    const userInfo = data && JSON.parse(data);

    useEffect(() => {
        appInsights.trackPageView({
            name: "Web Withdraw  - (WithdrawalStepOne.tsx)",
            isLoggedIn: true,
        });
    }, []);

    const { data: banks, isLoading: isLoadingBanks } = useFetchBanks({
        country_code: userInfo?.country,
    });

    const handleButtonClick = ({
        amount,
        accountNumber,
        description,
        }: FormData) => {
        setDetails({
            ...details,
            amount: amount,
            accountNumber: accountNumber,
            bankName: details.bankName,
            bankCode: bankCode,
            description,
        });
        setCustomerName("");
        if (!walletDetails.isTransactionPinSet) {
            setCurrentStep(5);
        } else {
            setCurrentStep(1);
        }
    };

    const onSubmit = ({ amount, accountNumber, description }: FormData) => {
        setError("");
        const formatAmount = amount?.replace(/,/g, "");
        handleButtonClick({
           amount: formatAmount,
           accountNumber,
           description,
        });
    };

    // Get bank code
    function getBankCode(bankName: string) {
        const result = banks?.find((bank: Bank) => {
            const foundBank = bank.name.toLowerCase() === bankName.toLowerCase();
            return foundBank ? setBankCode(bank.code) : null;
        });

        return result;
    }

    const fetchCustomerName = async () => {
        setIsLoadingCustomerName(true);
        try {
            const reqBody = {
                bank_code: bankCode,
                bank_account_num: details?.accountNumber,
            };

            const userDataResponse = await postData(
                apiEndpoints.customerValidateBankAccount,
                reqBody
            );

            if (details.accountNumber) {
                setError("");
            }

            setCustomerName(userDataResponse);
        } catch (error) {
            setError(error.response?.data?.message ?? error.message);
            appInsights.trackException({
                exception: error,
                properties: {
                    fileName: "Web Withdraw  - (WithdrawalStepOne.tsx)",
                },
            });
        } finally {
            setIsLoadingCustomerName(false);
        }
    };

    useEffect(() => {
        if (details?.bankName !== "" && details?.accountNumber?.length === 10) {
            fetchCustomerName();
        }
    }, [details.accountNumber]);

    useEffect(() => {
        if (details?.bankName) {
            getBankCode(details.bankName);
        }
    }, [details.bankName]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        // setInputValue(value);
        setDetails({ ...details, bankName: value, accountNumber: "" });
        setCustomerName("");

        setFilteredBankList(
            bankList.filter((bank) =>
                bank.name.toLowerCase().includes(value.toLowerCase())
            )
        );

        setShowDropdown(true);
    };

    const handleSelectBank = (bank: Bank) => {
        setDetails({ ...details, bankName: bank.name, accountNumber: "" });
        setCustomerName("");

        setShowDropdown(false);
    };

    const handleClose = (e: any) => {
        if (divRef?.current && !divRef?.current.contains(e.target)) {
            setShowDropdown(false);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // Allow digits and at most one decimal point
        const value = e.target.value.replace(/[^0-9.]/g, "");

        if (value > walletDetails?.availableBalance) {
            setWithdrawalError("Amount is more than wallet balance");
        } else {
            setWithdrawalError("");
        }

        // Ensure there's only one decimal point
        const parts = value.split(".");
        let formattedValue;
        if (parts.length > 2) {
            // More than one decimal point, ignore this input
            formattedValue = parts[0] + "." + parts[1];
        } else {
            formattedValue = value;
        }

        let formattedAmount: string;
        if (formattedValue === "") {
            formattedAmount = "";
        } else {
            const [integerPart, decimalPart] = formattedValue.split(".");
            if (decimalPart !== undefined) {
                formattedAmount =
                    new Intl.NumberFormat("en-US").format(Number(integerPart)) +
                    "." +
                    decimalPart;
            } else {
                formattedAmount = new Intl.NumberFormat("en-US").format(
                    Number(integerPart)
                );
            }
        }

        setFormattedValue(formattedAmount);
    };

    useEffect(() => {
        window.addEventListener("click", handleClose);

        return () => {
            window.removeEventListener("click", handleClose);
        };
    }, []);

    return (
        <div className={styles.stepOne_container}>
            {error && (
                <div
                    style={{ width: "100%", paddingTop: "20px", paddingBottom: "20px" }}
                >
                    <Alert
                        message={error}
                        type="error"
                        showIcon
                        closable
                        onClose={() => setError("")}
                    />
                </div>
            )}
            <header className={styles.stepOne_header}>
                <h3>Withdraw</h3>
                <p>Where would you like to withdraw into?</p>
            </header>

            <form className={styles.stepOne_form} onSubmit={handleSubmit(onSubmit)}>
                <div className={styles.amount_container}>
                    <div className={styles.amountInput_label_container}>
                        <label className={styles.amount_input_label}>Amount</label>
                        <input
                            type="text"
                            placeholder="₦"
                            name="amount"
                            ref={register}
                            className={`${styles.amount_field}
                            }`}
                            value={formattedValue}
                            onChange={handleChange}
                        />
                    </div>
                    <span className={styles.amount_error_container}>
            {errors.amount?.message}
          </span>
                    <span className={styles.errorStyles}>{withdrawalError}</span>
                </div>

                <div ref={divRef} className={styles.selectBankContainer}>
                    <label className="label-txt">Bank Name</label>
                    <input
                        type="text"
                        name="bankName"
                        ref={register}
                        placeholder={
                            isLoadingBanks
                                ? "loading, please wait"
                                : "E.g. Guaranty Trust Bank"
                        }
                        value={details.bankName}
                        onChange={handleInputChange}
                        onFocus={() => setShowDropdown(true)}
                        className={styles.selectedBankField}
                        disabled={isLoadingBanks}
                    />
                    {showDropdown && (
                        <ul className={styles.bankNameDropdown}>
                            {filteredBankList?.length > 0
                                ? filteredBankList?.map((bank) => (
                                    <li
                                        key={bank.id}
                                        onClick={() => handleSelectBank(bank)}
                                        style={{ padding: "8px", cursor: "pointer" }}
                                    >
                                        {bank.name}
                                    </li>
                                ))
                                : bankList?.map((bank) => (
                                    <li
                                        key={bank.id}
                                        onClick={() => handleSelectBank(bank)}
                                        style={{ padding: "8px", cursor: "pointer" }}
                                    >
                                        {bank.name}
                                    </li>
                                ))}
                        </ul>
                    )}
                    <span className={styles.errorStyles}>{errors.bankName?.message}</span>
                </div>

                {details.bankName && (
                    <>
                        <CustomInputField
                            type="number"
                            label="Account Number"
                            placeholder="0000000000"
                            name="accountNumber"
                            errors={errors.accountNumber}
                            reference={register}
                            maxLength={10}
                            onChange={(e: any) =>
                                setDetails({ ...details, accountNumber: e.target.value })
                            }
                        />

                        <div className={styles.user}>
                            {isLoadingCustomerName ? (
                                <Loader />
                            ) : (
                                <>
                                    <img src={user} alt="" />
                                    <span>{customerName?.bank_account_name}</span>
                                </>
                            )}
                        </div>

                        <CustomTextArea
                            name="description"
                            label="Description"
                            placeholder="Please enter a description"
                            reference={register}
                            errors={errors.description}
                            maxLength={50}
                        />
                    </>
                )}

                <p className={styles.charges}>
                    Transaction fee: ₦{userInfo?.transferCharge}
                </p>

                <div className={styles.submit_button}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={!customerName || !!withdrawalError || Number(formattedValue) <= 0 }
                    >
                        Proceed
                    </Button>
                </div>
            </form>
        </div>
    );
}

export default WithdrawalStepOne;
