import { useQuery } from "react-query";
import { apiEndpoints } from "../apis/apiEndpoints";
import { getData } from "../apis/apiMethods";

const fetchProducts = async ({
  aggregatorId,
  countryCode,
}: {
  aggregatorId: string;
  countryCode: string;
}) => {
  const response = await getData(
    `${apiEndpoints.customerLoanProducts}?aggregator_id=${aggregatorId}&country_code=${countryCode}`,
  );
  return response.data;
};

export default function useFetchProducts({
  aggregatorId,
  countryCode,
}: {
  aggregatorId: string;
  countryCode: string;
}) {
  return useQuery(
    [{ aggregatorId, countryCode }, "fetchProducts"],
    fetchProducts,
    {
      refetchOnWindowFocus: false,
      enabled: countryCode && aggregatorId ? true : false,
    },
  );
}
