import styles from "./NewOfferPopUp.module.scss";
import infoCircle from "../../assets/info-circle-orange.svg";
import React from "react";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../helpers/ROUTES";

function NewOfferPopUp() {
  const history = useHistory();

  return (
    <div className={styles.new_loan_popup_container}>
      <div className={styles.new_loan_popup}>
        <img src={infoCircle} alt="" />
        <span className={styles.popup_text}>New loan offer</span>
        <button
          className={styles.popup_btn}
          onClick={() => history.push(ROUTES.widgetNewLoanOffer)}
        >
          View Details
        </button>
      </div>
    </div>
  );
}

export default NewOfferPopUp;
