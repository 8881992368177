import styles from "../../Loans.module.scss";
import { formatCurrency } from "../../../../../../helpers/formatCurrency";
import { Button } from "antd";
import React from "react";
import { LoanOfferProps } from "../../../../../../web/components/features/newLoanOffer/NewLoanOffer";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../../../helpers/ROUTES";
import {
  updateLoanDetailsAction,
  updateWidgetSettingsAction,
} from "../../../../../../redux/actions";
import { useDispatch } from "react-redux";

interface NewOfferRejectedScreenProps {
  mostRecentLoanOffer: LoanOfferProps | null;
  loanData: any;
  refetchPendingLoanOffer: () => void;
}

function NewOfferRejectedScreen({
  mostRecentLoanOffer,
  loanData,
  refetchPendingLoanOffer,
}: NewOfferRejectedScreenProps) {
  const history = useHistory();
  const dispatch = useDispatch();

  function handleCloseModal() {
    refetchPendingLoanOffer();

    dispatch(
      updateLoanDetailsAction({
        new_loan_offer_widget: {
          offer_amount: null,
          offer_tenor: null,
        },
      }),
    );

    if (history.location.pathname === "/create-loan-account/loan/history") {
      dispatch(updateWidgetSettingsAction({ showNewLoanOffer: false }));
    } else {
      history.push(ROUTES.widgetDashboard);
    }
  }

  function generateContactUsUrl() {
    refetchPendingLoanOffer();
    dispatch(
      updateLoanDetailsAction({
        new_loan_offer_widget: {
          offer_amount: null,
          offer_tenor: null,
        },
      }),
    );

    history.push(ROUTES.widgetContactUs);
  }

  return (
    <div style={{maxWidth: "480px"}}>
      <div className={styles.offer_verification_failed}>
        <div className={styles.box}></div>
        <h3>Loan Declined</h3>
        <p>
          You have chosen to decline the loan offer of{" "}
          {`₦${formatCurrency(Number(mostRecentLoanOffer?.offer_amount || loanData?.new_loan_offer?.offer_amount))}`}
          . If you need assistance or have any questions, please contact our
          support team.
        </p>
      </div>

      <div className={styles.buttons}>
        <Button type="primary" onClick={handleCloseModal}>
          Done
        </Button>

        <Button
          type="primary"
          ghost
          role="link"
          aria-label="link"
          onClick={generateContactUsUrl}
        >
          Contact Support
        </Button>
      </div>
    </div>
  );
}

export default NewOfferRejectedScreen;
