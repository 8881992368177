import React, { Dispatch, SetStateAction, useEffect } from "react";
import styles from "./LoanRequest.module.scss";
import arrowLeft from "../../../../assets/web/arrow-left.svg";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { postData } from "../../../../apis/apiMethods";
import { decrypt } from "../../../../helpers/encryptor";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../interfaces/redux-interface";
import { ISuccess } from "../../../../interfaces/success";
import { appInsights } from "../../../../components/AppInsight/AppInsight";
import mixpanel from "mixpanel-browser";

type AlternativeLoanCompleteScreenProps = {
  onPrevStep: () => void;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  setSuccess: Dispatch<SetStateAction<ISuccess>>;
  setError: Dispatch<SetStateAction<string>>;
};

function AlternativeLoanCompleteScreen({
  onPrevStep,
  setCurrentStep,
  setSuccess,
  setError,
}: AlternativeLoanCompleteScreenProps) {
  const data = localStorage.getItem("currentUser");
  const userInfo = data && JSON.parse(data);
  const monoWidgetData = useSelector(
    (state: ReduxStoreModel) => state.monoWidgetDataReducer
  );
  const loanStateData = useSelector((state: ReduxStoreModel) => state.loanDetailsReducer)

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web  - (AlternativeLoanCompleteScreen.tsx)",
      isLoggedIn: true,
    });
  }, []);

  async function handleCreateLoanWithOutBankStatement() {
    setCurrentStep(4);
    try {
      const res = await postData(apiEndpoints.customerProcessCustomerLoan, {
        loan_amount: loanStateData?.loan_amount,
        loan_name: loanStateData?.loan_name,
        loan_tenure: loanStateData?.loan_tenure,
        product_id: loanStateData?.product_id,
        repayment_start_date: loanStateData?.repayment_start_date,
        bvn_checker: userInfo?.isBvnVerified,
        annual_interest_rate: loanStateData?.annual_interest_rate,
        aggregator_loan_ref: Date.now().toString(),
        bvn_number: decrypt(userInfo?.bvn),
        business_registration_number: loanStateData?.business_registration_number,
        phone_number: userInfo?.phoneNumber,
        country_code: userInfo?.country,
        aggregator_id: userInfo?.aggregatorId,
        customer_bank_account: loanStateData?.customer_bank_account,
        is_bankstatement_linkage_failed: true,
        loan_tenor_input_type: loanStateData?.loan_tenor_input_type,
        bank_statement_provider_response: JSON.stringify({
          opened: monoWidgetData?.opened,
          customer_email: monoWidgetData?.customer_email,
          opened_dateTimeStamp: monoWidgetData?.opened_dateTimeStamp,
          is_institution_selected: monoWidgetData?.is_institution_selected,
          selected_institution_name: monoWidgetData?.selected_institution_name,
          institution_selected_dateTimeStamp:
            monoWidgetData?.institution_selected_dateTimeStamp,
        }),
      });
      setSuccess({
        headerText: "Request Submitted Successfully",
        bodyText: res.message,
      });
      mixpanel.track('Get Loan', {
        'distinct_id': `${userInfo?.customerId}`,
        'loan amount': `${loanStateData?.loan_amount}`,
        'loan name': `${loanStateData?.loan_name}`,
        'loan tenor': `${loanStateData?.loan_tenure}`,
        'product name': `${loanStateData?.product_name}`,
        'Loan status': `successfully applied`,
        'bank statement linked status': `not linked`
      })
      setCurrentStep(5);
    } catch (error) {
      setError(error.response?.data?.message ?? error.message);
      setCurrentStep(6);
    }
  }

  return (
    <div className={styles.stepTen_container}>
      <img
        src={arrowLeft}
        alt=""
        className={styles.arrow}
        onClick={onPrevStep}
      />
      <header className={styles.stepTen_header}>
        <h3>Complete your loan application</h3>
      </header>

      <div className={styles.bankStatement_instructions}>
        <p>
          We noticed you couldn't link your bank statement. Providing us with
          more financial information improves your credit eligibility. To link
          your bank statement, you can:
        </p>

        <ul>
          <li>
            Retry linking your bank statement by accessing the “Link Bank
            Statement” option via the “Loans” menu on your dashboard or the
            profile page.
          </li>
          <li>
            Alternatively, you can request that your bank statement be sent to
            bankstatement@advancly.com through your mobile banking app or by
            contacting your bank directly.
          </li>
        </ul>
      </div>

      <button onClick={handleCreateLoanWithOutBankStatement}>Proceed</button>
    </div>
  );
}

export default AlternativeLoanCompleteScreen;
