import { Alert, Form, Input, Select } from "antd";
import user from "../../../assets/profile/user.svg";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { errorHandler } from "../../../helpers/errorHandler";
import { appInsights } from "../../AppInsight/AppInsight";
import { postDataWithKey } from "../../../apis/apiMethods";
import { useQueryCache } from "react-query";
import { ISuccess } from "../../../interfaces/success";
import { SuccessComponent } from "../WidgetRevampv2/SuccessComponent";
import Loader from "../../Loader/Loader";

type Bank = {
  code: string;
  country_code: string;
  id: number;
  longcode: string;
  name: string;
  slug: string;
};
interface IdirectDebit {
  customerId?: number;
  bankList?: Bank[];
  error?: string;
  setError?: Dispatch<SetStateAction<string>>;
  success?: ISuccess;
  setSuccess?: Dispatch<SetStateAction<ISuccess>>;
  setCurrentStep?: Dispatch<SetStateAction<number>>;
}
const DirectDebit = ({
  bankList,
  customerId,
  error,
  setError,
  setSuccess,
  success,
  setCurrentStep,
}: IdirectDebit) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loadingAccount, setLoadingAccount] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [directDebitBankDetails, setDirectDebitBankDetails] = useState({
    recipient_bank_code: "",
    recipient_account_number: "",
  });
  const directDebitUrl = `/customer/${customerId}/direct_debit_accounts`;
  const queryCache = useQueryCache();

  const fetchAccountName = async () => {
    setAccountName("");
    setLoadingAccount(true);

    try {
      const reqBody = {
        bank_code: directDebitBankDetails.recipient_bank_code,
        bank_account_num: directDebitBankDetails.recipient_account_number,
      };
      const response = await postDataWithKey(
        apiEndpoints.validateBankAccount,
        reqBody
      );

      if (setError && response) {
        setError("");
      }
      setAccountName(response.bank_account_name);
    } catch (error: any) {
      // setError(errorHandler(error.message));
      if (setError) {
        setError(error.response?.data?.message ?? error.message);
      }
    } finally {
      setLoadingAccount(false);
    }
  };

  const findBankName = bankList?.find(({ code }) => {
    return code === directDebitBankDetails?.recipient_bank_code;
  });

  const handleCloseSuccessComp = () => {
    if(setCurrentStep) {
      setCurrentStep(2)
    } else {
      setShowSuccess(false)
    }
  }

  async function handleSubmitDirectAccountDetails() {
    const reqBody = {
      account_name: accountName,
      account_number: directDebitBankDetails?.recipient_account_number,
      bank_code: directDebitBankDetails?.recipient_bank_code,
      bank_name: findBankName?.name || "",
      currency: "NGN",
    };
    try {
      setIsLoading(true);
      await postDataWithKey(directDebitUrl, reqBody);
      window.scrollTo(0, 0);

      if (setSuccess) {
        setSuccess({
          ...success,
          headerText: "Direct Debit Account Added Successfully",
          bodyText: `Proceed to select your direct debit account from the dropdown`,
        });
      }
      setIsLoading(false);
      if (customerId) {
        queryCache.invalidateQueries([
          { customerId },
          "getDirectDebitExistingAccounts",
        ]);
      }
      setShowSuccess(true);
    } catch (error: any) {
      setIsLoading(false);
      setAccountName("");

      if (setError) {
        setError(errorHandler(error));
      }
      appInsights.trackException({
        exception: error,
        properties: { fileName: DirectDebit },
      });
    }
  }

  useEffect(() => {
    if (
      directDebitBankDetails.recipient_bank_code !== "" &&
      directDebitBankDetails.recipient_account_number?.length === 10
    ) {
      fetchAccountName();
    }
  }, [directDebitBankDetails]);

  if (showSuccess) {
    return (
      <SuccessComponent
        title={success?.headerText!}
        body={success?.bodyText!}
        handler={handleCloseSuccessComp}
        btnText="Proceed"
        type="success"
        setSuccess={setSuccess}
      />
    );
  }

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Loader />
      </div>
    );
  }

  return (
    <div>
      {error && (
        <div>
          <Alert
            message={error}
            type="error"
            showIcon
            closable
            onClose={() => {
              if (setError) {
                setError("");
              }
            }}
          />
        </div>
      )}
      <header className="">
        <h3>Direct Debit Account</h3>
        <p>Please add a Direct debit account</p>
      </header>

      <Form layout="vertical">
        <Form.Item
          className="mb-3 mb-md-0 mt-2"
          name="bank_name"
          label="Bank Name"
          rules={[{ required: true, message: "Required field" }]}
        >
          <Select
            placeholder="Select Bank"
            allowClear
            onChange={(value) =>
              setDirectDebitBankDetails({
                ...directDebitBankDetails,
                recipient_bank_code: value,
              })
            }
            showSearch
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={
              bankList &&
              bankList.map((item) => ({
                label: item.name,
                value: item.code,
              }))
            }
          >
            {bankList &&
              bankList?.length > 0 &&
              bankList.map((item) => (
                <Select.Option key={item.id} value={item.code}>
                  {item.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
        <Form.Item
          className="mb-3 mb-md-0 mt-2"
          initialValue=""
          id="accountNumber"
          name="recipient_account_number"
          label="Account Number"
          rules={[
            { required: true, message: "Required field" },
            {
              min: 10,
              message: "Account number must be 10 digits",
            },
          ]}
        >
          <Input
            onChange={(e) => {
              setDirectDebitBankDetails({
                ...directDebitBankDetails,
                recipient_account_number: e.target.value,
              });
            }}
          />
        </Form.Item>

        <div className="d-flex align-items-center">
          <img
            src={user}
            className="img-fluid mr-2"
            alt=""
            style={{
              width: "11.48px",
              height: "13.2px",
              objectFit: "contain",
            }}
          />
          <small className="color-mid-purple account__name">
            {loadingAccount ? "Loading...." : accountName}
          </small>
        </div>

        <div className="d-flex justify-content-end">
          <div className="d-flex align-items-center">
            <button
              className=" btn advancly-btn-nobg-noborder  cursor-pointer"
              onClick={handleSubmitDirectAccountDetails}
              disabled={isLoading || !accountName}
            >
              Submit New Account
              {isLoading && (
                <span
                  className="spinner-border text-white "
                  style={{
                    width: "10px",
                    height: "10px",
                  }}
                />
              )}
            </button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default DirectDebit;
