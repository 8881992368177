import { defaultCustomStyles, IWidgetState } from "../../interfaces/borrower";
import { ReduxActionModel } from "../../interfaces/redux-interface";
import { WIDGET_SETTINGS } from "../actions/types";

let initialState: IWidgetState = {
  allow_bank_details: false,
  allow_contact_us: false,
  allow_pin_change: false,
  allow_sms_notification: false,
  allow_view_balance: false,
  allow_withdrawal: false,
  bank_details_input_enabled: false,
  bankList: [],
  bankStatementWidgetOpened: false,
  canCloseWidget: false,
  customStyles: defaultCustomStyles,
  edit_widget: false,
  encryptedKey: "",
  finishedFirstInitializations: false,
  public_key: "",
  showWidgetAnimation: false,
  state_token: "",
  use_wallet: false,
  worldCountries: [],
  showNewLoanOffer: false,
};

export const updateWidgetSettingsReducer = (
  state = initialState,
  action: ReduxActionModel,
) => {
  const { type, payload } = action;
  switch (type) {
    case WIDGET_SETTINGS:
      return { ...state, ...payload };
    default:
      return state;
  }
};
