import React, { useState } from "react";
import { apiEndpoints } from "../../../../../../apis/apiEndpoints";
import { appInsights } from "../../../../../AppInsight/AppInsight";
import { useHistory } from "react-router-dom";
import { postDataWithKey } from "../../../../../../apis/apiMethods";
import { errorHandler } from "../../../../../../helpers/errorHandler";
import { Alert } from "antd";
import { ROUTES } from "../../../../../../helpers/ROUTES";
import { SuccessComponent } from "../../../SuccessComponent";
import mixpanel from "mixpanel-browser";
import advLogo from "../../../../../../assets/advancly-logo-full.png";
import loader from "../../../../../../assets/loadinggif.gif";

function WidgetAlternativeLoanCompleteScreen({
  createLoanData,
  setCreateLoanData,
  handleLoanRequestSuccess,
  email,
  encryptedKey,
  handleGoBack,
  setError,
  error,
  monoWidgetStateData,
}: any) {
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const history = useHistory();

  const handleAction = () => {
    setError("");
    handleGoBack();
  };

  async function handleCreateLoanWithoutBankStatement() {
    setIsLoading(true);

    //Below appinsights is tracking the loan  data
    appInsights.trackEvent({
      name: "WidgetCreateLoanData",
      properties: createLoanData,
    });
    try {
      const loanRequestResponse = await postDataWithKey(
        apiEndpoints.processLoan,
        {
          ...createLoanData,
          is_bankstatement_linkage_failed: true,
          bank_statement_provider_response: JSON.stringify(monoWidgetStateData),
        },
        //@ts-ignore
        encryptedKey,
      );

      if (loanRequestResponse?.status) {
        setSuccessMsg(loanRequestResponse?.message);
        setShowSuccess(true);

        mixpanel.track("Get Loan", {
          distinct_id: `${email}`,
          "loan amount": `${createLoanData?.loan_amount}`,
          "loan name": `${createLoanData?.loan_name}`,
          "loan tenor": `${createLoanData?.loan_tenure}`,
          product_id: `${createLoanData?.product_id}`,
          "Loan status": `successfully applied`,
          "bank statement linked status": `not linked`,
        });
      }

      if (handleLoanRequestSuccess) {
        handleLoanRequestSuccess(loanRequestResponse);
        // refetchFetchWalletdetails();
      }
    } catch (error) {
      setError(errorHandler(error));
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: WidgetAlternativeLoanCompleteScreen },
      });

      // if (createLoanData && setCreateLoanData) {
      //   setCreateLoanData({
      //     ...createLoanData,
      //     customer_bank_account: "",
      //   });
      // }
    }
    setIsLoading(false);
  }

  if (showSuccess) {
    return (
      <SuccessComponent
        title="Request Successful!"
        body={`${successMsg}`}
        handler={() => history.push(ROUTES.widgetDashboard)}
        btnText="Done"
        type="success"
      />
    );
  }

  if (isLoading) {
    return (
      <>
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={advLogo}
            alt=""
            className="img-fluid"
            style={{
              height: "24px",
              width: "126.53px",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={loader}
            alt=""
            className="img-fluid"
            style={{
              height: "20%",
              width: "50%",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
      </>
    );
  }

  return (
    <>
      {error && (
        <div>
          <Alert
            message={error}
            type="error"
            showIcon
            closable
            onClose={() => setError!("")}
          />
        </div>
      )}
      <div>
        <h4>Complete your loan application</h4>
        <br />
        <div
          className="card"
          style={{ backgroundColor: "#F4F5F6", borderRadius: "10px" }}
        >
          <div className="card-body">
            <p>
              We noticed you couldn't link your bank statement. Providing us
              with more financial information improves your credit eligibility.
              To link your bank statement, you can:
            </p>
            <ul>
              <li>
                Retry linking your bank statement by accessing the “Link Bank
                Statement” option via the “Loans” menu on your dashboard or the
                profile page.
              </li>
              <li>
                Alternatively, you can request that your bank statement be sent
                to bankstatement@advancly.com through your mobile banking app or
                by contacting your bank directly.
              </li>
            </ul>
          </div>
        </div>

        <div
          className="d-flex justify-content-end"
          style={{ marginTop: "10%" }}
        >
          <div className="d-flex align-items-center">
            <p
              role="button"
              aria-roledescription="button"
              className="text-primary padding-none mr-3 cursor-pointer"
              onClick={handleAction}
            >
              Cancel my request
            </p>
            <button
              className="btn btn-primary btn-radius"
              disabled={isLoading}
              onClick={handleCreateLoanWithoutBankStatement}
            >
              Proceed
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default WidgetAlternativeLoanCompleteScreen;
