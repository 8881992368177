import React, { useState, Dispatch, SetStateAction } from "react";
import { Alert, Button, Form, Input, Select } from "antd";
import { nigerianStateData } from "../../../../../../helpers/nigerianStatesAndLga";
import { ROUTES } from "../../../../../../helpers/ROUTES";
import { postDataWithKey } from "../../../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../../../apis/apiEndpoints";
import advLogo from "../../../../../../assets/advancly-logo-full.png";
import loader from "../../../../../../assets/loadinggif.gif";

interface ResidentialInformationProps {
  setError: Dispatch<SetStateAction<string>>;
  error: string;
  aggregator_id: number;
  customer_id: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  handleNext: () => void;
}

function ResidentialInformation({
  error,
  setError,
  aggregator_id,
  customer_id,
  setCurrentStep,
  handleNext,
}: ResidentialInformationProps) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [filteredStateValues, setFilteredStateValues] = useState<any>([]);

  const handleSetSelectedLoan = (name: string) => {
    const response = nigerianStateData?.find((state) => state.name === name);
    setFilteredStateValues(response);
  };

  const onSubmit = async (values: {
    state: string;
    lga: string;
    address: string;
  }) => {
    setIsLoading(true);
    const payload = {
      state: values.state,
      lga: values.lga,
      street_address: values.address,
      customer_id: customer_id,
    };
    try {
      const response = await postDataWithKey(
        `${apiEndpoints.createResidentialInformationWidget}?aggregator_id=${aggregator_id}`,
        payload,
      );

      if (response.status) {
        handleNext();
      }
    } catch (error) {
      setError(error?.response?.data?.message ?? error?.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <>
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={advLogo}
            alt=""
            className="img-fluid"
            style={{
              height: "24px",
              width: "126.53px",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={loader}
            alt=""
            className="img-fluid"
            style={{
              height: "20%",
              width: "50%",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
      </>
    );
  }

  return (
    <>
      {error && (
        <div style={{ paddingBottom: "20px" }}>
          <Alert
            message={error}
            type="warning"
            showIcon
            closable
            onClose={() => {
              if (setError) {
                setError("");
              }
            }}
          />
        </div>
      )}

      <h4>Residential Information</h4>

      <Form
        layout="vertical"
        form={form}
        onFinish={(values) => onSubmit(values)}
      >
        <Form.Item
          className="mb-3 mb-md-0 mt-2"
          name="state"
          label="State"
          rules={[{ required: true, message: "Required field" }]}
        >
          <Select
            placeholder="Select"
            allowClear
            onChange={(val) => {
              handleSetSelectedLoan(val);
            }}
          >
            {nigerianStateData &&
              nigerianStateData?.map((state) => (
                <Select.Option value={state.name} key={state.name}>
                  {state.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          className="mb-3 mb-md-0 mt-2"
          name="lga"
          label="Local Government Area"
          rules={[{ required: true, message: "Required field" }]}
        >
          <Select placeholder="Select" allowClear>
            {filteredStateValues &&
              filteredStateValues?.localGovernments?.map((lga: any) => (
                <Select.Option value={lga} key={lga}>
                  {lga}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          className="mb-3 mb-md-0 mt-2"
          name="address"
          label="Enter your street address"
          rules={[
            { required: true, message: "Please enter your street address!" },
          ]}
        >
          <Input />
        </Form.Item>

        <div
          className="d-flex justify-content-end"
          style={{ marginTop: "10%" }}
        >
          <div className="d-flex align-items-center">
            <p
              className="text-primary padding-none mr-3 cursor-pointer"
              onClick={() => setCurrentStep(2)}
            >
              Go Back
            </p>
            <Button
              type="primary"
              htmlType="submit"
              style={{ borderRadius: "5px", height: "45px" }}
            >
              Submit
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
}

export default ResidentialInformation;
