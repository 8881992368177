import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { Alert, Button } from "antd";
import styles from "./LoanRequest.module.scss";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupValidators } from "../../../../helpers/yupValidators";
import useFetchProducts from "../../../../custom-hooks/useFetchProducts";
import { LoanProduct } from "./LoanRequest";
import { postData } from "../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import arrowLeft from "../../../../assets/web/arrow-left.svg";
import arrowDown from "../../../../assets/web/arrow-down.svg";
import arrowUp from "../../../../assets/web/arrow-up-1.svg";
import { useDispatch } from "react-redux";
import {
  updateLoanDetailsAction,
  updateWebSettingsAction,
} from "../../../../redux/actions";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../interfaces/redux-interface";
import Loader from "../../../../components/Loader/Loader";
import { useLocation } from "react-router-dom";
import { appInsights } from "../../../../components/AppInsight/AppInsight";

const schema = yup.object().shape({
  amount: yupValidators.amount,
  loanProduct: yupValidators.loanProduct,
  tenor: yupValidators.tenor,
});

interface LoanRequestProps {
  setCurrentStep: Dispatch<SetStateAction<number>>;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
}

interface FormData {
  amount: string;
  loanProduct: string;
  tenor: string;
  repaymentDate: string;
  firstRepaymentDate: string;
  numberOfRepayments: string;
}

function LoanRequestStepOne({
  setCurrentStep,
  error,
  setError,
}: LoanRequestProps) {
  const [loanInputData, setLoanInputData] = useState({
    amount: "",
    tenor: "",
  });
  const [inputFieldError, setInputFieldError] = useState({
    amount: "",
    tenor: "",
  });
  const [loading, setLoading] = useState(false);
  const [productList, setProductList] = useState<LoanProduct[]>([]);
  const [tenorList, setTenorList] = useState([]);
  const [filteredProductList, setFilteredProductList] = useState<LoanProduct[]>(
    []
  );
  const [selectedProductName, setSelectedProductName] = useState("");
  const [tenor, setTenor] = useState("");
  const [selectedTenorValue, setSelectedTenorValue] = useState<number | null>();
  const [selectedProductId, setSelectedProductId] = useState<number>();
  const dispatch = useDispatch();
  const selectedLoanData = useSelector(
    (state: ReduxStoreModel) => state.loanDetailsReducer
  );
  const [showProductDropdown, setShowProductDropdown] =
    useState<boolean>(false);
  const [showTenorDropdown, setShowTenorDropdown] = useState<boolean>(false);
  const [customTenor, setCustomTenor] = useState(false);
  const productDivRef = useRef<HTMLDivElement>(null);
  const tenorDivRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const data = localStorage.getItem("currentUser");
  const userInfo = data && JSON.parse(data);

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web  - (LoanRequestStepOne.tsx)",
      isLoggedIn: true,
    });
  }, []);

  const urlExtension = location.pathname.split("/").filter(Boolean);

  const { data: products, isLoading: isLoadingproducts } = useFetchProducts({
    countryCode: userInfo?.country,
    aggregatorId: userInfo?.aggregatorId,
  });

  const getSelectedProductId = (name: string) => {
    const result = products?.find((product: LoanProduct) => {
      const productId =
        product.product_name.toLowerCase() === name.toLowerCase();
      return productId ? setSelectedProductId(product.id) : null;
    });

    return result;
  };

  const getTenorValue = (tenor: string) => {
    const result = tenorList?.find((tenors: any) => {
      const tenorValue = tenors.tenor === tenor;
      return tenorValue ? setSelectedTenorValue(tenors.tenorValue) : null;
    });
    return result;
  };

  useEffect(() => {
    getSelectedProductId(selectedProductName);
  }, [selectedProductName]);

  useEffect(() => {
    getTenorValue(tenor);
  }, [tenor]);

  useEffect(() => {
    setProductList(products);
  }, [isLoadingproducts]);

  const selectedProductTenor = () => {
    const result = products?.find((product: any) => {
      const productName =
        product.product_name.toLowerCase() ===
        selectedProductName.toLowerCase();
      return productName ? setTenorList(product.tenors) : null;
    });

    return result;
  };

  useEffect(() => {
    selectedProductTenor();
  }, [selectedProductName]);

  useEffect(() => {
    if (selectedProductId) {
      const chosenProduct = products?.find(
        (product: LoanProduct) => product.id === Number(selectedProductId)
      );

      dispatch(
        updateLoanDetailsAction({
          id: chosenProduct?.id,
          maximum_tenor: chosenProduct?.maximum_tenor,
          maximum_amount: chosenProduct?.maximum_amount,
          aggregator_id: chosenProduct?.aggregator_id,
          product_name: chosenProduct?.product_name,
          repayment_type: chosenProduct?.repayment_type,
        })
      );
    }
  }, [products, selectedProductId]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    fieldName: string
  ) => {
    // Remove non-numeric characters
    const rawValue = e.target.value.replace(/\D/g, "");
    let formattedValue: string;

    if (fieldName === "amount") {
      if (rawValue !== "" && !selectedProductName) {
        setError("Please select a loan product");
      } else if (selectedProductName) {
        setError("");
        if (rawValue === "") {
          formattedValue = "";
        } else {
          formattedValue = new Intl.NumberFormat("en-US").format(
            Number(rawValue)
          );
        }
        setLoanInputData((prevData) => ({
          ...prevData,
          [fieldName]: formattedValue,
        }));
      }

      const enterValue = Number(rawValue);

      if (
        selectedLoanData?.maximum_amount &&
        enterValue > selectedLoanData?.maximum_amount
      ) {
        setInputFieldError({
          ...inputFieldError,
          amount: `Maximum loan amount is ${selectedLoanData?.maximum_amount.toLocaleString()}`,
        });
      } else {
        setInputFieldError({
          ...inputFieldError,
          amount: "",
        });
      }
    } else if (fieldName === "tenor") {
      const parsedTenor = parseInt(rawValue, 10);
      if (parsedTenor && !selectedProductName) {
        setError("Please select a loan product");
      } else if (selectedProductName) {
        setLoanInputData((prevData) => ({
          ...prevData,
          [fieldName]: isNaN(parsedTenor) ? "" : String(parsedTenor),
        }));
        setSelectedTenorValue(parsedTenor)
      }

      const enterValue = Number(rawValue);

      if (selectedLoanData?.maximum_amount && selectedLoanData?.maximum_tenor) {
        if (
          selectedLoanData?.maximum_amount &&
          enterValue > selectedLoanData?.maximum_tenor
        ) {
          setInputFieldError({
            ...inputFieldError,
            tenor: `Maximum loan tenor is ${selectedLoanData?.maximum_tenor}days`,
          });
        } else {
          setInputFieldError({
            ...inputFieldError,
            tenor: "",
          });
        }
      }
    }
  };

  const handleProductChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSelectedProductName(value);
    if (value) {
      setError("");
    } else {
      setError("Please select a loan product");
    }

    if (value) {
      setError("");
    }

    setFilteredProductList(
      productList?.filter((product) =>
        product.product_name.toLowerCase().includes(value.toLowerCase())
      )
    );

    setShowProductDropdown(true);
  };

  const handleTenorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (value !== "" && !selectedProductName) {
      setError("Please select a loan product");
    } else {
      setTenor(value);
      setError("");

      setShowTenorDropdown(true);
    }
  };

  const handleSelectedProduct = (product: LoanProduct) => {
    setSelectedProductName(product.product_name);
    if (product) {
      setError("");
    }

    setShowProductDropdown(false);
  };

  const handleSelectedTenor = (tenor: any) => {
    setTenor(tenor.tenor);
    if (tenor) {
      setError("");
    }

    setShowTenorDropdown(false);
  };

  const onSubmit = async ({ amount, tenor }: FormData) => {
    setError("");
    if (selectedLoanData?.product_name) {
      if (inputFieldError.amount || inputFieldError.tenor) return;
      setLoading(true);

      const payload = {
        productId: selectedLoanData.id,
        loanTenor: selectedTenorValue,
        principalAmount: Number(amount!.replace(/\$\s?|(,*)/g, "").trim()),
        aggregatorId: userInfo?.aggregatorId,
        loanTenorInputType: customTenor ? "Custom" : "Default",
      };

      try {
        const res = await postData(
          `${apiEndpoints.generateLoanDetails}?isWidget=${false}`,
          payload
        );

        if (res?.status) {
          res.data.loan_amount = Number(
            amount!.replace(/\$\s?|(,*)/g, "").trim()
          );

          res.data.product_name =
            res.data.product_name ?? selectedLoanData?.product_name;

          res.data.repayment_type =
            res.data.repayment_type ?? selectedLoanData?.repayment_type;

          dispatch(
            updateLoanDetailsAction({
              loan_amount: res.data.totalPrincipalDisbursed,
              loan_name: selectedLoanData?.product_name,
              product_id: selectedLoanData.id,
              loan_tenure: Number(selectedTenorValue),
              business_registration_number:
                userInfo?.business_registration_number,

              currency: { code: res.data?.currency.code },
              totalPrincipalDisbursed: res.data?.totalPrincipalDisbursed,
              totalInterestCharged: res.data?.totalInterestCharged,
              loanTermInDays: res.data?.loanTermInDays,
              totalRepaymentExpected: res.data?.totalRepaymentExpected,
              product_name: res.data?.product_name,
              repayment_type: res.data?.repayment_type,
              periods: res.data?.periods,
              loan_tenor_input_type: customTenor ? "Custom" : "Default",
            })
          );
        }

        setCurrentStep(1);
      } catch (error) {
        setError(error.response?.data?.message ?? error.message);
        appInsights.trackException({
          exception: error,
          properties: {
            fileName: "Web - (LoanRequestStepOne.tsx)",
          },
        });
      } finally {
        setLoading(false);
        dispatch(
          updateLoanDetailsAction({ maximum_amount: null, maximum_tenor: null })
        );
      }
    }
  };

  const handleCloseProductList = (e: any) => {
    if (productDivRef?.current && !productDivRef?.current.contains(e.target)) {
      setShowProductDropdown(false);
    }
  };

  const handleCloseTenorList = (e: any) => {
    if (tenorDivRef?.current && !tenorDivRef?.current.contains(e.target)) {
      setShowTenorDropdown(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleCloseProductList);
    window.addEventListener("click", handleCloseTenorList);

    return () => {
      window.removeEventListener("click", handleCloseProductList);
      window.removeEventListener("click", handleCloseTenorList);
    };
  }, []);

  const handlePreviousPage = () => {
    dispatch(
      updateWebSettingsAction({
        showLoanRequestModal: false,
        showLoanOptions: true,
      })
    );
  };

  return (
    <div className={styles.stepOne_container}>
      {error && (
        <div style={{ width: "100%", paddingTop: "60px" }}>
          <Alert
            message={error}
            type="warning"
            showIcon
            closable
            onClose={() => setError("")}
          />
        </div>
      )}
      {urlExtension[1] !== "loan" && (
        <img
          src={arrowLeft}
          alt=""
          className={styles.arrow}
          onClick={handlePreviousPage}
        />
      )}
      <header className={styles.stepOne_header}>
        <h3>Loan Request</h3>
        <p>Enter the following information to begin</p>
      </header>
      <form className={styles.stepOne_form} onSubmit={handleSubmit(onSubmit)}>
        <div ref={productDivRef} className={styles.selectProductContainer}>
          <label className="label-txt">Loan Product</label>
          <div className={styles.product_input_field_container}>
            <input
                type="text"
                name="loanProduct"
                ref={register}
                placeholder={
                  isLoadingproducts ? "loading, please wait..." : "Select"
                }
                value={selectedProductName}
                onChange={handleProductChange}
                onFocus={() => setShowProductDropdown(true)}
                className={styles.input_field}
                disabled={isLoadingproducts}
            />
            {!showProductDropdown ?
                <img
                    src={arrowDown}
                    alt=""
                    className={styles.input_pointer} onClick={() => setShowProductDropdown(true)}
                /> :
                <img
                    src={arrowUp}
                    alt=""
                    className={styles.input_pointer} onClick={() => setShowProductDropdown(false)}
                />
            }
          </div>
          {showProductDropdown && (
              <ul className={styles.productNameDropdown}>
                {filteredProductList.length > 0
                    ? filteredProductList?.map((product) => (
                        <li
                            key={product.id}
                            onClick={() => handleSelectedProduct(product)}
                            style={{padding: "8px", cursor: "pointer"}}
                        >
                        {product.product_name}
                    </li>
                  ))
                : productList?.map((product) => (
                    <li
                      key={product.id}
                      onClick={() => handleSelectedProduct(product)}
                      style={{ padding: "8px", cursor: "pointer" }}
                    >
                      {product.product_name}
                    </li>
                  ))}
            </ul>
          )}
          <span className={styles.alert}>{errors.loanProduct?.message}</span>
        </div>

        <div className={styles.input_container}>
          <div className={styles.input_label_container}>
            <label>
              Loan Amount{" "}
              {selectedLoanData?.maximum_amount ? (
                <span>
                  (maximum loan amount is ₦
                  {selectedLoanData?.maximum_amount?.toLocaleString()})
                </span>
              ) : null}
            </label>
            <input
              type="text"
              // placeholder="e.g. 50,000"
              name="amount"
              ref={register}
              className={`${styles.input_field} ${
                inputFieldError.amount !== "" && styles.error
              }`}
              value={loanInputData.amount}
              onChange={(e) => handleChange(e, "amount")}
            />
          </div>
          <span className={styles.alert}>{errors.amount?.message}</span>
          <span className={styles.alert}>{inputFieldError.amount}</span>
        </div>

        <div ref={tenorDivRef} className={styles.selectTenorContainer}>
          {customTenor ? (
                  <div className={styles.customTenorContainer}>
                    <div className={styles.customRepaymentValue}>
                      <div className={styles.input_label_container}>
                        <label className="label-txt">
                          Number of Repayments{" "}
                          {selectedLoanData?.maximum_tenor ? (
                              <span>
                        (maximum number of days is{" "}
                                {selectedLoanData?.maximum_tenor})
                      </span>
                          ) : null}
                        </label>

                        <input
                            type="text"
                            // placeholder="e.g. 10"
                            name="tenor"
                            ref={register}
                            className={`${styles.input_field} ${
                                inputFieldError.tenor !== "" && styles.error
                            }`}
                            value={loanInputData.tenor}
                            onChange={(e) => handleChange(e, "tenor")}
                        />

                    </div>
                    <span className={styles.alert}>{errors.tenor?.message}</span>
                    <span className={styles.alert}>{inputFieldError.tenor}</span>
                  </div>

              <Button
                  className={styles.switcher}
                  type="primary"
                  ghost
                  onClick={() => {
                    setTenor("");
                    setCustomTenor(false);
                  }}
              >
                Pick Tenor
              </Button>
            </div>
            ) : (
            <>
            <label className="label-txt">Loan Tenor</label>
            <div className={styles.tenor_input_field_container}>
              <input
                type="text"
                name="tenor"
                  ref={register}
                  placeholder="Select"
                  value={tenor}
                  onChange={handleTenorChange}
                  onFocus={() => {
                    if (!selectedProductName) {
                      setError("Please select a loan product");
                    } else {
                      setShowTenorDropdown(true);
                    }
                  }}
                  className={styles.input_field}
                  disabled={isLoadingproducts}
                />
              {!showTenorDropdown ?
                  <img
                      src={arrowDown}
                      alt=""
                      className={styles.input_pointer}
                      onClick={() => setShowTenorDropdown(true)}
                  /> :
                  <img
                      src={arrowUp}
                      alt=""
                      className={styles.input_pointer}
                      onClick={() => setShowTenorDropdown(false)}
                  />
              }
            </div>
              {showTenorDropdown && (
                <ul className={styles.tenorValueDropdown}>
                  {tenorList?.map((tenor: any) => (
                    <li
                      key={tenor.tenorValue}
                      onClick={() => handleSelectedTenor(tenor)}
                      style={{ padding: "8px", cursor: "pointer" }}
                    >
                      {tenor.tenor}
                    </li>
                  ))}
                  <li style={{ padding: "8px", cursor: "pointer" }}>
                    <Button
                      type="default"
                      onClick={() => {
                        setSelectedTenorValue(null);
                        setCustomTenor(true);
                      }}
                    >
                      Custom Tenor
                    </Button>
                  </li>
                </ul>
              )}
              <span className={styles.alert}>{errors.tenor?.message}</span>
            </>
          )}
        </div>

        {selectedLoanData && (
          <div className={styles.input_container}>
            <div className={styles.input_label_container}>
              <label>Repayment Frequency</label>
              <input
                type="text"
                value={selectedLoanData?.repayment_type}
                disabled
                style={{ color: "#8b8699" }}
                className={styles.input_field}
              />
            </div>
          </div>
        )}

        <div className={styles.loader}></div>

        <Button
            type='primary'
            htmlType='submit'
            className={styles.submit_button}
            loading={loading}
            disabled={loading || !!inputFieldError.amount || !!inputFieldError.tenor}
        >
          {loading ? "Processing loan..." : "Proceed"}
        </Button>
      </form>
    </div>
  );
}

export default LoanRequestStepOne;
