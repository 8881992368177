import React, { useEffect, useState } from "react";
import styles from "./Withdraw.module.scss";
import closeIcon from "../../../../assets/web/close-square.svg";
import useFetchBanks from "../../../../custom-hooks/useFetchBanks";
import { Bank } from "../../../../interfaces/banks";
import WithdrawalStepOne from "./WithdrawalStepOne";
import WithdrawalStepTwo from "./WithdrawalStepTwo";
import Spinner from "./Spinner";
import Success from "./Success";
import Failed from "./Failed";
import Begin from "./setTransactionPinWithdrawal/Begin";
import SecurityQuestion from "./setTransactionPinWithdrawal/SecurityQuestion";
import Pin from "./setTransactionPinWithdrawal/Pin";
import { WalletDetails } from "../../../../interfaces/wallet";
import { useDispatch } from "react-redux";
import { updateWebSettingsAction } from "../../../../redux/actions";
import mixpanel from "mixpanel-browser";

interface WithdrawProps {
  refetchTransactions: () => void;
  refetchFetchWalletdetails: () => void;
  walletDetails: WalletDetails;
}

export interface FormData {
  amount?: string;
  bankName?: string;
  bankCode?: string;
  accountNumber?: string;
  description?: string;
}

function Withdraw({
  refetchTransactions,
  refetchFetchWalletdetails,
  walletDetails,
}: WithdrawProps) {
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [pin, setPin] = useState("");
  const [isLoadingCustomerName, setIsLoadingCustomerName] = useState(false);
  const [customerName, setCustomerName] = useState<any>();
  const [bankList, setBankList] = useState<Bank[]>([]);
  const [bankCode, setBankCode] = useState("");
  const [details, setDetails] = useState<FormData>({
    amount: "",
    bankName: "",
    description: "",
    accountNumber: "",
    bankCode: "",
  });

  const dispatch = useDispatch();
  const data = localStorage.getItem("currentUser");
  const userInfo = data && JSON.parse(data);

  const { data: banks, isLoading: isLoadingBanks } = useFetchBanks({
    country_code: userInfo?.country,
  });

  useEffect(() => {
    mixpanel.track_pageview({"page": "Withdrawal - Web"});
  }, []);

  useEffect(() => {
    setBankList(banks);
  }, [isLoadingBanks]);

  const handleRetry = () => {
    setCurrentStep(0);
  };

  const handlePreviousStep = () => {
    setCurrentStep((prev) => prev - 1);
    setCustomerName("")
    setDetails(prev => ({...prev, amount: "", bankName: "", bankCode: "", accountNumber: ""}))
    setError("")
    setSuccess("")
  };

  const handleCompletedTransaction = () => {
    refetchTransactions();
    refetchFetchWalletdetails();
    dispatch(updateWebSettingsAction({ showWithdraw: false }));
  };

  const handleClose = () => {
    document.body.style.overflow = "auto";
    setCustomerName("")
    setError("")
    setSuccess("")
    setDetails(prev => ({...prev, amount: "", bankName: "", bankCode: "", accountNumber: ""}))
    dispatch(updateWebSettingsAction({ showWithdraw: false }));
  };

  return (
    <>
      <div className={styles.overlay}></div>
      <div className={styles.modal_container}>
        <div className={styles.modal_steps_wrapper}>
          {currentStep === 0 && (
            <WithdrawalStepOne
              setCurrentStep={setCurrentStep}
              walletDetails={walletDetails}
              setBankCode={setBankCode}
              bankCode={bankCode}
              setDetails={setDetails}
              details={details}
              bankList={bankList}
              setIsLoadingCustomerName={setIsLoadingCustomerName}
              isLoadingCustomerName={isLoadingCustomerName}
              setCustomerName={setCustomerName}
              customerName={customerName}
              setError={setError}
              error={error}
            />
          )}
          {currentStep === 1 && (
            <WithdrawalStepTwo
              handlePreviousStep={handlePreviousStep}
              setCurrentStep={setCurrentStep}
              error={error}
              setError={setError}
              details={details}
              pin={pin}
              setPin={setPin}
              bankCode={bankCode}
            />
          )}
          {currentStep === 2 && <Spinner />}
          {currentStep === 3 && (
            <Success handleCompletedTransaction={handleCompletedTransaction} />
          )}
          {currentStep === 4 && (
            <Failed error={error} handleRetry={handleRetry} />
          )}
          {currentStep === 5 && <Begin setCurrentStep={setCurrentStep} />}
          {currentStep === 6 && (
            <SecurityQuestion
              setCurrentStep={setCurrentStep}
              error={error}
              setError={setError}
              success={success}
              setSuccess={setSuccess}
            />
          )}
          {currentStep === 7 && (
            <Pin
              setCurrentStep={setCurrentStep}
              error={error}
              setError={setError}
              success={success}
              setSuccess={setSuccess}
              currentStep={currentStep}
            />
          )}
          <img
            src={closeIcon}
            alt=""
            className={styles.cancel_button}
            onClick={handleClose}
          />
        </div>
      </div>
    </>
  );
}

export default Withdraw;
