import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Form, Input, InputNumber, Select } from "antd";
import { LoanProduct, StepProp } from "./LoanRequest";
import { formatCurrency } from "../../../../../../helpers/formatCurrency";
import { postDataWithKey } from "../../../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../../../apis/apiEndpoints";
import { Alert } from "antd";
import { errorHandler } from "../../../../../../helpers/errorHandler";
import { appInsights } from "../../../../../AppInsight/AppInsight";
import advLogo from "../../../../../../assets/advancly-logo-full.png";
import loader from "../../../../../../assets/loadinggif.gif";

const LoanRequestStepTwo = ({
  handleNext,
  handleGoBack,
  loanProducts,
  loanData,
  setLoanData,
  encryptedKey,
  setProcessedLoanData,
  setCreateLoanData,
  createLoanData,
  isLoanParentLoading,
  setRepaymentFrequency,
  error,
  setError,
}: StepProp) => {
  let location = useLocation();
  const [form] = Form.useForm();
  const [selectedLoanProduct, setSelectedLoanProduct] =
    useState<LoanProduct | null>();
  const [isLoading, setIsLoading] = useState(false);
  const [customTenor, setCustomTenor] = useState(false);

  let maximumProductAmount = formatCurrency(
    selectedLoanProduct?.maximum_amount || 0,
  );

  let maximumProductTenure = selectedLoanProduct?.maximum_tenor || 0;

  const handleSetSelectedLoan = (id: number) => {
    const response = loanProducts?.find((l) => l.id === id);
    setSelectedLoanProduct(response);
  };

  useEffect(() => {
    appInsights.trackPageView({
      name: "LoanRequestStepTwo.tsx",
      isLoggedIn: true,
    });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const onSubmit = async (values: {
    productId: number;
    loanTenor: string;
    principalAmount: string;
  }) => {
    let principalAmount = Number(
      values.principalAmount!.replace(/\$\s?|(,*)/g, "").trim(),
    );
    if (setLoanData) {
      setLoanData({
        ...loanData,
        productId: Number(values.productId),
        loanTenor: Number(values.loanTenor),
        principalAmount,
        loan_name: selectedLoanProduct?.product_name,
      });
      if (setError) {
        setError("");
      }
      setIsLoading(true);
      try {
        loanData.productId = Number(values.productId);
        loanData.loanTenor = Number(values.loanTenor);
        loanData.principalAmount = principalAmount;
        loanData.loan_name = selectedLoanProduct?.product_name;
        const response = await postDataWithKey(
          apiEndpoints.generateLoanDetails,
          {
            productId: Number(values.productId),
            loanTenor: Number(values.loanTenor),
            principalAmount: principalAmount,
            loan_name: selectedLoanProduct?.product_name,
            loanEffectiveDate: new Date(),
            aggregatorId: loanData.aggregator_id,
            loanTenorInputType: customTenor ? "Custom" : "Default",
          },
          // @ts-ignore
          encryptedKey,
        );
        response.data.product_name = loanData.loan_name;
        response.data.loan_amount = principalAmount;

        if (setProcessedLoanData) {
          setProcessedLoanData(response.data);
          if (selectedLoanProduct?.repayment_type && setRepaymentFrequency) {
            setRepaymentFrequency(selectedLoanProduct?.repayment_type);
          }
        }
        if (createLoanData && setCreateLoanData) {
          console.log({LOAN_REQUEST_STEP_TWO_CUSTOM_TENOR: customTenor})
          setCreateLoanData({
            ...createLoanData,
            loan_amount: response.data.totalPrincipalDisbursed,
            loan_name: selectedLoanProduct?.product_name,
            product_id: Number(values.productId),
            loan_tenure: Number(values.loanTenor),
            loan_tenor_input_type: customTenor ? "Custom" : "Default",
          });
        }

        if (response?.status) {
          handleNext();
        }
      } catch (error) {
        if (setError) {
          setError(errorHandler(error));
        }
        appInsights.trackException({
          //@ts-ignore
          exception: error,
          properties: { fileName: LoanRequestStepTwo },
        });
      } finally {
        form.setFieldsValue({ principalAmount: "", loanTenor: "" });
      }
      setIsLoading(false);
    } else {
      if (setError) {
        setError("Something went wrong, please try again");
      }
    }
  };

  if (isLoading || isLoanParentLoading) {
    return (
      <>
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={advLogo}
            alt=""
            className="img-fluid"
            style={{
              height: "24px",
              width: "126.53px",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={loader}
            alt=""
            className="img-fluid"
            style={{
              height: "20%",
              width: "50%",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
      </>
    );
  }

  // const loanAmountInputStyle = {
  //   width: "100%",
  //   height: "55px",
  //   borderRadius: "10px",
  //   border: "1px solid #d9d6e0"

  //   hover:
  // }

  return (
    <>
      {error && (
        <div style={{ paddingBottom: "20px" }}>
          <Alert
            message={error}
            type="warning"
            showIcon
            closable
            onClose={() => {
              if (setError) {
                setError("");
              }
            }}
          />
        </div>
      )}
      <p className="text-muted">Enter your loan information</p>
      <Form
        layout="vertical"
        form={form}
        onFinish={(values) => onSubmit(values)}
      >
        <Form.Item
          className="mb-3 mb-md-0 mt-2"
          name="productId"
          label="Loan product"
          rules={[{ required: true, message: "Required field" }]}
        >
          <Select
            placeholder="Select"
            allowClear
            onChange={(val) => {
              handleSetSelectedLoan(val);
            }}
          >
            {loanProducts &&
              loanProducts?.length > 0 &&
              loanProducts.map((item) => (
                <Select.Option value={item.id} key={item.id}>
                  {item.product_name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          className="mb-3 mb-md-0 mt-2"
          initialValue=""
          name="principalAmount"
          label={`Loan amount ${
            selectedLoanProduct?.maximum_amount
              ? `(maximum loan amount ${maximumProductAmount})`
              : ""
          }`}
          rules={[
            { required: true, message: "Required field" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  !value ||
                  (selectedLoanProduct?.maximum_amount &&
                    selectedLoanProduct?.maximum_amount >=
                      Number(value!.replace(/\$\s?|(,*)/g, "")))
                ) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error(
                    `Maximum loan amount for the specific loan product is ${formatCurrency(
                      selectedLoanProduct?.maximum_amount || 0,
                    )}`,
                  ),
                );
              },
            }),
          ]}
        >
          <div>
            <InputNumber
              controls={false}
              size="large"
              formatter={(value) =>
                ` ${`${value}`.replace(/\D/g, "")}`.replace(
                  /\B(?=(\d{3})+(?!\d))/g,
                  ",",
                )
              }
              parser={(value) => value!.replace(/\$\s?|(,*)/g, "")}
              style={{ width: "100%" }}
            />
          </div>
          {/* <input className={styles.loanAmountInputField} /> */}
        </Form.Item>

        {customTenor ? (
          <div
            style={{ display: "flex", flexDirection: "column", width: "100%" }}
          >
            <Form.Item
              className="mb-3 mb-md-0 mt-2"
              initialValue=""
              name="loanTenor"
              label={`Number of Repayments ${
                maximumProductTenure
                  ? `(maximum number of days is ${maximumProductTenure})`
                  : ""
              }  `}
              rules={[
                { required: true, message: "Required field" },
                {
                  pattern: /^\d*[.]?\d*$/,
                  message: "Enter numbers from 0 - 9",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      !value ||
                      (selectedLoanProduct?.maximum_tenor &&
                        selectedLoanProduct?.maximum_tenor >= Number(value))
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        `Maximum number of days for this product is ${
                          selectedLoanProduct?.maximum_tenor &&
                          selectedLoanProduct?.maximum_tenor
                        }`,
                      ),
                    );
                  },
                }),
              ]}
            >
              <Input />
            </Form.Item>
            <Button
              style={{ placeSelf: "end" }}
              type="primary"
              ghost
              onClick={() => setCustomTenor(false)}
            >
              Pick Tenor
            </Button>
          </div>
        ) : (
          <Form.Item
            className="mb-3 mb-md-0 mt-2"
            name="loanTenor"
            label="Loan Tenor"
            rules={[{ required: true, message: "Required field" }]}
          >
            <Select placeholder="Select" allowClear={false}>
              {selectedLoanProduct &&
                selectedLoanProduct?.tenors?.length > 0 &&
                selectedLoanProduct?.tenors?.map((item) => (
                  <Select.Option value={item.tenorValue} key={item.tenorValue}>
                    {item.tenor}
                  </Select.Option>
                ))}
              <Select.Option>
                <Button onClick={() => setCustomTenor(true)}>
                  Custom Tenor
                </Button>
              </Select.Option>
            </Select>
          </Form.Item>
        )}
        {selectedLoanProduct?.repayment_type && (
          <Form.Item
            className="mb-3 mb-md-0 mt-2"
            name="repaymentFrequency"
            initialValue={selectedLoanProduct?.repayment_type}
            label={`Repayment Frequency `}
            rules={[{ required: true, message: "Required field" }]}
          >
            <Input disabled value={selectedLoanProduct?.repayment_type} />
          </Form.Item>
        )}
        <div
          className="d-flex justify-content-end"
          style={{ marginTop: "20%" }}
        >
          <div className="d-flex align-items-center">
            <p
              className="text-primary padding-none mr-3 cursor-pointer"
              onClick={handleGoBack}
            >
              Go Back
            </p>
            <button className="btn btn-primary btn-radius">Next</button>
          </div>
        </div>
      </Form>
    </>
  );
};

export default LoanRequestStepTwo;
