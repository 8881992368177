import { IBorrowerData } from "../../interfaces/borrower";
import { ReduxActionModel } from "../../interfaces/redux-interface";
import { BORROWER_DETAILS } from "../actions/types";

let initialState: IBorrowerData = {
  customer_category: "",
  aggregator_domain: "",
  aggregator_id: 0, // aggregatorId (web)
  aggregator_name: "",
  bank_account_number: "",
  bank_code: "",
  bank_statement_connected: false,
  bank_statement_provider: undefined,
  bank_statement_provider_id: undefined,
  borrower_phone: "", // phoneNumber (Web)
  business_registration_number: "", // business_rc_number (web)
  bvn_number: "", // bvn (web)
  company_name: "",
  country_code: "", // countryCode (web)
  currency: undefined,
  customer_id: 0,
  customer_type: "",
  email: "",
  first_name: "",
  first_time_borrower: false,
  last_name: "",
  returningUserDetails: undefined,
  isApi: false,
  password: "",
  phoneNoCountryDialCode: "",
  gender: "",
  isEmailVerified: false,
  dob: "",
  isBvnValidated : false,
  partner: "",
  country: "",
  isTransactionPinSet: false,
  transferCharge: 0,
  accountName: "",
  wallet_id: "",
  isDefault: false,
  accountStatus: "",
  firstTimeLogin: false,
  session_timeout: null
};

export const updateBorrowerDetailsReducer = (
  state = initialState,
  action: ReduxActionModel
) => {
  const { type, payload } = action;
  switch (type) {
    case BORROWER_DETAILS:
      return { ...state, ...payload };
    default:
      return state;
  }
};
