import React, { useEffect } from "react";
import styles from "../Withdraw.module.scss";
import TransactionPINSVG from "../../../../../assets/web/set-transaction-pin.svg";
import { Button } from "antd";
import { appInsights } from "../../../../../components/AppInsight/AppInsight";

interface BeginProps {
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
}

function Begin({ setCurrentStep }: BeginProps) {

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web Withdraw  - (Begin.tsx)",
      isLoggedIn: true,
    });
  }, []);
  
  return (
    <div className={styles.stepFive_container}>
      <div className={styles.content_wrap}>
        <div className={styles.featureIcon}>
          <img src={TransactionPINSVG} alt="" />
        </div>
        <div className={styles.content}>
          <h2 className={styles.title}>Set Up Transaction PIN</h2>
          <p className={styles.content_body}>
            Create a transaction pin to perform wallet transactions on your
            account.
          </p>
        </div>
      </div>

      <div className={styles.submit_button}>
        <Button
          type="primary"
          onClick={() => setCurrentStep((prev) => prev + 1)}
        >
          Begin Setup
        </Button>
      </div>
    </div>
  );
}

export default Begin;
