import React, { useEffect } from 'react'
import styles from "./LoanRequest.module.scss"
import successSVG from "../../../../assets/web/success.svg"
import { ISuccess } from '../../../../interfaces/success'
import { appInsights } from '../../../../components/AppInsight/AppInsight'

interface LoanRequestSuccessProps {
  // refetchLoanHistory: () => void
  success: ISuccess
  setSuccess: React.Dispatch<React.SetStateAction<ISuccess>>
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>
  closeModal: () => void
}

function LoanRequestSuccess({ success, closeModal, setSuccess, setCurrentStep}: LoanRequestSuccessProps) {

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web  - (LoanRequestSuccess.tsx)",
      isLoggedIn: true,
    });
  }, []);

  const handleNextStep = () => {
    // refetchLoanHistory()
    if(success.headerText === "Request Submitted Successfully") {
      closeModal()
    } else if (success.headerText === "Direct Debit Account Added Successfully") {
      setCurrentStep(8)
    }

    setSuccess({
      headerText: "",
      bodyText: ""
    })
  }

  return (
    <div className={styles.stepSeven_container}>
        <div className={styles.verification_success}>
            <img src={successSVG} alt='' />
            <h3>{success.headerText}</h3>
            <p>{success.bodyText}</p>

            <button className={styles.submit_button} onClick={handleNextStep}>{success.headerText === "Request Submitted Successfully" ? "Done" : "Proceed"}</button>
        </div>
    </div>
  )
}

export default LoanRequestSuccess