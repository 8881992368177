import React, { useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Alert, Checkbox, message } from "antd";
import { ROUTES } from "../../../../../../helpers/ROUTES";
import {
  postDataWithKey,
  putDotNetDataWithKey,
} from "../../../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../../../apis/apiEndpoints";
import { errorHandler } from "../../../../../../helpers/errorHandler";
import { SuccessComponent } from "../../../SuccessComponent";
import Loader from "../../../../../Loader/Loader";
import { appInsights } from "../../../../../AppInsight/AppInsight";
import CONFIG from "../../../../../../helpers/config";
import MonoConnect from "@mono.co/connect.js";
import Okra from "okra-js";
import useFetchTermsAndConditions from "../../../../../../custom-hooks/useFetchTermsAndConditions";
// @ts-ignore
import DOMPurify from "dompurify";
import {
  updateBorrowerDetailsAction,
  updateWidgetSettingsAction,
} from "../../../../../../redux/actions";
import { useDispatch } from "react-redux";
import mixpanel from "mixpanel-browser";
import advLogo from "../../../../../../assets/advancly-logo-full.png";
import loader from "../../../../../../assets/loadinggif.gif";

const LoanRequestStepFour = ({
  createLoanData,
  encryptedKey,
  handleGoBack,
  handleLoanRequestSuccess,
  updateBankStatementWidgetStatus,
  bank_statement_provider,
  bank_statement_connected,
  walletDetails,
  setCurrentStep,
  first_name,
  last_name,
  email,
  error,
  setError,
  monoWidgetStateData,
  setMonoWidgetStateData,
}: any) => {
  let location = useLocation();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [isSavingProvider, setIsSavingProvider] = useState(false);
  const dispatch = useDispatch();

  const monoWidgetData = useRef({
    opened: false,
    opened_dateTimeStamp: null,
    customer_email: null,
    is_institution_selected: false,
    selected_institution_name: null,
    institution_selected_dateTimeStamp: null,
  });
  useEffect(() => {
    appInsights.trackPageView({
      name: "LoanRequestStepFour.tsx",
      isLoggedIn: true,
    });
  }, []);
  const accountLinkSuccessRef = useRef<boolean | null>(null);

  function createMarkup(html: any) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }

  function handleLaunchOkraWidget() {
    if (!CONFIG.OKRA_PUBLIC_KEY || !CONFIG.OKRA_CLIENT_TOKEN) {
      return setError("Update Config for Okra");
    }
    dispatch(
      updateWidgetSettingsAction({
        bankStatementWidgetOpened: true,
      }),
    );

    Okra.buildWithOptions({
      name: "ADVANCLY",
      env: CONFIG.OKRA_ENV,
      app_id: "", // app_id from your app builder
      key: CONFIG.OKRA_PUBLIC_KEY,
      token: CONFIG.OKRA_CLIENT_TOKEN,
      products: ["auth", "transactions"], //others are ['auth','identity','balance','transactions', 'income'](in lowercase)
      onSuccess: function (data: any) {
        console.log("options success", data);
        handleSaveCustomerCode(data?.customer_id, "okra");
      },
      onClose: function () {
        console.log("options close");
      },
    });
  }

  const monoConnect = useMemo(() => {
    if (!CONFIG.MONO_PUBLIC_KEY) {
      return setError("Update Config for Mono");
    }
    if (updateBankStatementWidgetStatus) {
      updateBankStatementWidgetStatus();
    }

    const customer = {
      name: `${first_name} ${last_name}`,
      email: email,
    };
    const monoInstance = new MonoConnect({
      onClose: () => {
        if (!accountLinkSuccessRef.current) {
          setCurrentStep(4);
        } else {
          console.log("Widget closed successfully");
        }
        setMonoWidgetStateData(monoWidgetData.current);
        //Below appinsights is tracking the widget data
        appInsights.trackEvent({
          name: "MonoWidgetData",
          properties: {
            ...monoWidgetData.current,
            AccountLinkStatus: accountLinkSuccessRef.current,
          },
        });
      },
      onLoad: () => console.log("Widget loaded successfully"),
      key: CONFIG.MONO_PUBLIC_KEY,
      data: { customer },
      onSuccess: async (data: { code: string }) => {
        accountLinkSuccessRef.current = true;
        handleSaveCustomerCode(data?.code, "mono");
      },
      onEvent: async (eventName: string, data: any) => {
        if (eventName === "OPENED") {
          monoWidgetData.current = {
            ...monoWidgetData.current,
            opened: true,
            opened_dateTimeStamp: data?.timestamp,
            customer_email: email,
          };
        } else if (eventName === "INSTITUTION_SELECTED") {
          monoWidgetData.current = {
            ...monoWidgetData.current,
            is_institution_selected: true,
            selected_institution_name: data.institution.name,
            institution_selected_dateTimeStamp: data?.timestamp,
          };
        }
      },
    });

    monoInstance.setup();

    return monoInstance;
  }, []);

  const handleSaveCustomerCode = async (
    code: string,
    provider: "okra" | "mono",
  ) => {
    //Call d api to store the code
    console.log({ customerId: code });
    const reqBody = {
      bank_statement_provider_id: provider === "okra" ? 1 : 2,
      bank_statement_provider_customer_code: provider === "okra" ? code : "",
      authentication_code: provider === "mono" ? code : "",
      customer_id: walletDetails?.customerId,
      aggregator_id: walletDetails?.aggregatorId,
    };

    try {
      setIsSavingProvider(true);
      await putDotNetDataWithKey(
        apiEndpoints.saveBankStatementProvider,
        reqBody,
        //@ts-ignore
        encryptedKey,
      );
      message.success("Bank provider details saved successfully");

      dispatch(
        updateBorrowerDetailsAction({
          bank_statement_connected: true,
        }),
      );

      await handleCreateLoan();
    } catch (error) {
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: "LoanRequestStepFour.tsx" },
      });
      setError(
        error?.response?.data?.message ??
          "Something went wrong please try again",
      );
    } finally {
      setIsSavingProvider(false);
    }
  };
  useEffect(() => {
    console.log({
      updateBankStatementWidgetStatus,
      bank_statement_provider,
      bank_statement_connected,
    });
  }, [
    updateBankStatementWidgetStatus,
    bank_statement_provider,
    bank_statement_connected,
  ]);

  const handleBankStatementOrCreateLoan = () => {
    if (!bank_statement_connected) {
      if (bank_statement_provider?.toLowerCase() === "okra") {
        handleLaunchOkraWidget();
      } else {
        monoConnect.open();
      }
    } else {
      handleCreateLoan().finally();
    }
  };

  async function handleCreateLoan() {
    setIsLoading(true);

    //Below appInsights is tracking the loan  data
    appInsights.trackEvent({
      name: "WidgetCreateLoanData",
      properties: createLoanData,
    });

    try {
      const loanRequestResponse = await postDataWithKey(
        apiEndpoints.processLoan,
        {
          ...createLoanData,
          is_bankstatement_linkage_failed: false,
          bank_statement_provider_response: monoWidgetStateData
            ? JSON.stringify(monoWidgetStateData)
            : "linked",
        },
        //@ts-ignore
        encryptedKey,
      );

      if (loanRequestResponse?.status) {
        setSuccessMsg(loanRequestResponse?.message);
        setShowSuccess(true);

        mixpanel.track("Get Loan", {
          distinct_id: `${email}`,
          "loan amount": `${createLoanData?.loan_amount}`,
          "loan name": `${createLoanData?.loan_name}`,
          "loan tenor": `${createLoanData?.loan_tenure}`,
          product_id: `${createLoanData?.product_id}`,
          "Loan status": `successfully applied`,
          "bank statement linked status": `linked`,
        });
      }

      if (handleLoanRequestSuccess) {
        handleLoanRequestSuccess(loanRequestResponse);
        // refetchFetchWalletdetails();
      }
    } catch (error) {
      setError(errorHandler(error));
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: LoanRequestStepFour },
      });

      // if (createLoanData && setCreateLoanData) {
      //   setCreateLoanData({
      //     ...createLoanData,
      //     customer_bank_account: "",
      //   });
      // }
    }
    setIsLoading(false);
  }

  const { data: termsAndConditions, isLoading: isLoadingTermsAndConditions } =
    useFetchTermsAndConditions({
      aggregatorId: String(walletDetails?.aggregatorId),
    });

  if (showSuccess) {
    return (
      <SuccessComponent
        title="Request Successful!"
        body={`${successMsg}`}
        handler={() => history.push(ROUTES.widgetDashboard)}
        btnText="Done"
        type="success"
      />
    );
  }
  if (isLoading || isSavingProvider) {
    return (
      <>
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={advLogo}
            alt=""
            className="img-fluid"
            style={{
              height: "24px",
              width: "126.53px",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={loader}
            alt=""
            className="img-fluid"
            style={{
              height: "20%",
              width: "50%",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
      </>
    );
  }

  return (
    <>
      {error && (
        <div style={{ paddingBottom: "20px" }}>
          <Alert
            message={error}
            type="warning"
            showIcon
            closable
            onClose={() => setError!("")}
          />
        </div>
      )}
      <div>
        <h4>Terms and agreement</h4>
        <br />
        {isLoadingTermsAndConditions ? (
          <Loader message="Loading terms and condition..." />
        ) : (
          <div
            className="card"
            style={{ backgroundColor: "#F4F5F6", borderRadius: "10px" }}
          >
            <div className="card-body">
              <div
                dangerouslySetInnerHTML={createMarkup(
                  termsAndConditions.termsAndConditions,
                )}
              ></div>
              <Checkbox
                className="text-muted"
                onChange={(e) => {
                  setAcceptTerms(e.target.checked);
                }}
              >
                I agree to these terms and condition.
              </Checkbox>
            </div>
          </div>
        )}

        <div
          className="d-flex justify-content-end"
          style={{ marginTop: "10%" }}
        >
          <div className="d-flex align-items-center">
            <p
              className="text-primary padding-none mr-3 cursor-pointer"
              onClick={handleGoBack}
            >
              Cancel my request
            </p>
            <button
              className="btn btn-primary btn-radius"
              disabled={!acceptTerms}
              onClick={handleBankStatementOrCreateLoan}
            >
              I understand
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoanRequestStepFour;
