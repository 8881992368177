import React, { useState, useEffect } from "react";
import embedLogo from "../../../assets/web/Embed.svg";
import { Link, useHistory } from "react-router-dom";
import arrowLeft from "../../../assets/web/arrow-left.svg";
import CustomInputField from "../../../components/CustomHTMLElements/EmbedCustomInputField";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { yupValidators } from "../../../helpers/yupValidators";
import styles from "./ResetPassword.module.scss";
import { resetPasswordInitialStepPostData } from "../../../apis/apiMethods";
import { apiEndpoints } from "../../../apis/apiEndpoints";
import { Alert, Button } from "antd";
import { ResetPasswordData } from "./ResetPassword";
import { useDispatch, useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../interfaces/redux-interface";
import { updateWebSettingsAction } from "../../../redux/actions";
import { appInsights } from '../../../components/AppInsight/AppInsight'

const schema = yup.object().shape({
  email: yupValidators.email,
});

interface StepOneProps {
  onNextStep: () => void;
  setResetPasswordData: React.Dispatch<React.SetStateAction<ResetPasswordData>>;
  setUserEmail: React.Dispatch<React.SetStateAction<string>>;
}

function StepOne({
  onNextStep,
  setResetPasswordData,
  setUserEmail,
}: StepOneProps) {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const webStateData = useSelector(
    (state: ReduxStoreModel) => state.webSettingsReducer
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web Step1  - (ResetPassword.tsx)",
      isLoggedIn: true,
    });
  }, []);

  const onSubmit = async({email}: {email: string}) => {
    setIsLoading(true)
    const reqBody = {
      email,
      reset_step: "first_step",
    };
    try {
      const res = await resetPasswordInitialStepPostData(apiEndpoints.resetPasswordUrl, reqBody)

      setUserEmail(email);
      setResetPasswordData({
        email,
        stateToken: res.state_token,
      });
      setIsLoading(false);
      onNextStep();
    } catch (error) {
      dispatch(
        updateWebSettingsAction({
          errorMsg: error.response?.data?.message ?? error.message,
        })
      );
      appInsights.trackException({
        exception: error,
        properties: {
          fileName: "Web Step1 - (ResetPassword.tsx)",
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  function handleBackNavigation() {
    history.goBack()

    dispatch(updateWebSettingsAction({ errorMsg: "" }));
  }

  return (
    <div className={styles.reset_password__container}>
      {webStateData.errorMsg && (
        <div
          style={{ width: "100%", paddingTop: "50px", paddingBottom: "20px" }}
        >
          <Alert
            message={webStateData.errorMsg}
            type="error"
            showIcon
            closable
            onClose={() => dispatch(updateWebSettingsAction({ errorMsg: "" }))}
          />
        </div>
      )}
      <header className={styles.logo__header_diff_step_three}>
        <div className={styles.div_diff_step_three}>
          <img src={embedLogo} alt="" />
          <Link to="/" className={styles.logo_diff_step_three}>
            embed
          </Link>
        </div>

        <button
          onClick={handleBackNavigation}
          className={styles.button_diff_step_three}
        >
          <img src={arrowLeft} alt="" />
          <span>Go back</span>
        </button>
      </header>

      <section className={styles.section_diff_step_three}>
        <div className={styles.section_div_diff_step_three}>
          <header className={styles.section_header_diff_step_three}>
            <h1>Reset Password</h1>
            <p>Enter the email linked to your account to proceed.</p>
          </header>

          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <CustomInputField
              type="email"
              placeholder="example@gmail.com"
              name="email"
              label="Email address"
              reference={register}
              errors={errors.email}
            />

            <div className={styles.submit__btn_diff_step_three}>
              <Button type="primary" htmlType="submit" disabled={isLoading}>
                {isLoading ? " Processing..." : "Proceed"}
              </Button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}

export default StepOne;
