import React, { useEffect, useState } from "react";
import styles from "./TransactionPin.module.scss";
import SecurityQuestion from "./securityQuestion/SecurityQuestion";
import Pin from "./pin/Pin";
import Spinner from "./spinner/Spinner";
import Success from "./success/Success";
import ResetPin from "./resetPin/ResetPin";
import Failed from "./failed/Failed";
import {useLocation} from "react-router-dom";
import arrowLeft from "../../../../assets/web/arrow-left.svg";
import { WalletDetails } from "../../../../interfaces/wallet";

enum ModalProfile {
  ModalProfileNavigator = "ModalProfileNavigator",
  ProfileInformation = "ProfileInformation",
  Card = "Card",
  DirectDebitAccountWeb = "DirectDebitAccountWeb",
  BankStatement = "BankStatement",
  Transaction = "Transaction",
  Password = "Password",
}

interface TransactionPinProps {
  setCurrentSection?: React.Dispatch<React.SetStateAction<ModalProfile>>;
  walletDetails: WalletDetails;
}

function TransactionPin({ setCurrentSection, walletDetails }: TransactionPinProps) {
  const [currentStep, setCurrentStep] = useState(0);
  const location = useLocation();
  const data = localStorage.getItem("currentUser");
  const userInfo = data && JSON.parse(data);

  const handleSelection = () => {
    if (setCurrentSection) {
      setCurrentSection(ModalProfile.ModalProfileNavigator);
    }
  };

  useEffect(() => {
    if (walletDetails?.isTransactionPinSet) {
      setCurrentStep(4);
    } else {
      setCurrentStep(0);
    }
  }, [walletDetails?.isTransactionPinSet]);

  return (
    <div
      className={`${styles.transactionPin_container} ${
        location.pathname === `/${userInfo?.aggregator_name}` &&
        styles.alignment
      }`}
    >
      {location.pathname === `/${userInfo?.aggregator_name}` && (
        <img
          src={arrowLeft}
          alt=""
          className={styles.left}
          onClick={handleSelection}
        />
      )}
      {currentStep === 0 && (
        <SecurityQuestion setCurrentStep={setCurrentStep} />
      )}
      {currentStep === 1 && <Pin setCurrentStep={setCurrentStep} />}
      {currentStep === 2 && <Spinner currentStep={currentStep} />}
      {currentStep === 3 && <Success />}
      {currentStep === 4 && <ResetPin setCurrentStep={setCurrentStep} />}
      {currentStep === 5 && <Failed />}
    </div>
  );
}

export default TransactionPin;
