import React, { useMemo, useRef, useState } from "react";
import styles from "../../Loans.module.scss";
import { appInsights } from "../../../../../AppInsight/AppInsight";
import CONFIG from "../../../../../../helpers/config";
import MonoConnect from "@mono.co/connect.js";
import Okra from "okra-js";
import { Alert } from "antd";
import {
  postDataWithKey,
  putDotNetDataWithKey,
} from "../../../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../../../apis/apiEndpoints";
import { errorHandler } from "../../../../../../helpers/errorHandler";
import { SuccessComponent } from "../../../SuccessComponent";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../../../helpers/ROUTES";
import { useDispatch } from "react-redux";
import {
  updateBorrowerDetailsAction,
  updateWidgetSettingsAction,
} from "../../../../../../redux/actions";
import mixpanel from "mixpanel-browser";
import advLogo from "../../../../../../assets/advancly-logo-full.png";
import loader from "../../../../../../assets/loadinggif.gif";

function WidgetBankStatementLinkageErrorScreen({
  handleNext,
  createLoanData,
  encryptedKey,
  setCreateLoanData,
  handleLoanRequestSuccess,
  bank_statement_provider,
  bank_statement_connected,
  walletDetails,
  first_name,
  last_name,
  email,
  error,
  setError,
  setSuccess,
  success,
  monoWidgetStateData,
  setMonoWidgetStateData,
}: any) {
  const [isSavingProvider, setIsSavingProvider] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const history = useHistory();
  const monoWidgetData = useRef({
    opened: false,
    opened_dateTimeStamp: null,
    customer_email: null,
    is_institution_selected: false,
    selected_institution_name: null,
    institution_selected_dateTimeStamp: null,
  });

  const dispatch = useDispatch();

  const handleAction = () => {
    setError("");
    setSuccess({ ...success, headerText: "", bodyText: "" });

    handleNext();
  };

  function handleLaunchOkraWidget() {
    if (!CONFIG.OKRA_PUBLIC_KEY || !CONFIG.OKRA_CLIENT_TOKEN) {
      return setError("Update Config for Okra");
    }
    dispatch(
      updateWidgetSettingsAction({
        bankStatementWidgetOpened: true,
      }),
    );
    Okra.buildWithOptions({
      name: "ADVANCLY",
      env: CONFIG.OKRA_ENV,
      app_id: "", // app_id from your app builder
      key: CONFIG.OKRA_PUBLIC_KEY,
      token: CONFIG.OKRA_CLIENT_TOKEN,
      products: ["auth", "transactions"], //others are ['auth','identity','balance','transactions', 'income'](in lowercase)
      onSuccess: function (data: any) {
        console.log("options success", data);
        handleSaveCustomerCode(data?.customer_id, "okra");
      },
      onClose: function () {
        console.log("options close");
      },
    });
  }

  const monoConnect = useMemo(() => {
    if (!CONFIG.MONO_PUBLIC_KEY) {
      return setError("Update Config for Mono");
    }
    dispatch(
      updateWidgetSettingsAction({
        bankStatementWidgetOpened: true,
      }),
    );

    const customer = {
      name: `${first_name} ${last_name}`,
      email: email,
    };

    const monoInstance = new MonoConnect({
      onClose: () => {
        console.log("Widget closed");
        setMonoWidgetStateData(monoWidgetData.current);
      },
      onLoad: () => console.log("Widget loaded successfully"),
      key: CONFIG.MONO_PUBLIC_KEY,
      data: { customer },
      onSuccess: async (data: { code: string }) => {
        handleSaveCustomerCode(data?.code, "mono");
      },
      onEvent: async (eventName: string, data: any) => {
        if (eventName === "OPENED") {
          console.log("Widget is open!");
          monoWidgetData.current = {
            ...monoWidgetData.current,
            opened: true,
            opened_dateTimeStamp: data?.timestamp,
            customer_email: email,
          };
        } else if (eventName === "INSTITUTION_SELECTED") {
          monoWidgetData.current = {
            ...monoWidgetData.current,
            is_institution_selected: true,
            selected_institution_name: data.institution.name,
            institution_selected_dateTimeStamp: data?.timestamp,
          };
        }
      },
    });

    monoInstance.setup();

    return monoInstance;
  }, []);

  const handleSaveCustomerCode = async (
    code: string,
    provider: "okra" | "mono",
  ) => {
    //Call d api to store the code
    console.log({ customerId: code });
    const reqBody = {
      bank_statement_provider_id: provider === "okra" ? 1 : 2,
      bank_statement_provider_customer_code: provider === "okra" ? code : "",
      authentication_code: provider === "mono" ? code : "",
      customer_id: walletDetails?.customerId,
      aggregator_id: walletDetails?.aggregatorId,
    };
    console.log("checking the customer flow");

    try {
      setIsSavingProvider(true);
      await putDotNetDataWithKey(
        apiEndpoints.saveBankStatementProvider,
        reqBody,
        //@ts-ignore
        encryptedKey,
      );
      setSuccess({
        ...success,
        bodyText: "Bank provider details saved successfully",
      });

      dispatch(
        updateBorrowerDetailsAction({
          bank_statement_connected: true,
        }),
      );
      handleCreateLoan();
    } catch (error) {
      setError(error?.response?.data?.message ?? error.message);
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: "LoanRequestStepFour.tsx" },
      });
    } finally {
      setIsSavingProvider(false);
    }
  };

  const handleBankStatementOrCreateLoan = () => {
    if (!bank_statement_connected) {
      if (bank_statement_provider?.toLowerCase() === "okra") {
        handleLaunchOkraWidget();
      } else {
        monoConnect.open();
      }
    }
  };

  async function handleCreateLoan() {
    setIsLoading(true);

    //Below appinsights is tracking the loan  data
    appInsights.trackEvent({
      name: "WidgetCreateLoanData",
      properties: createLoanData,
    });
    try {
      const loanRequestResponse = await postDataWithKey(
        apiEndpoints.processLoan,
        {
          ...createLoanData,
          is_bankstatement_linkage_failed: false,
          bank_statement_provider_response: JSON.stringify(monoWidgetStateData),
        },
        //@ts-ignore
        encryptedKey,
      );

      if (loanRequestResponse?.status) {
        setSuccessMsg(loanRequestResponse?.message);
        setShowSuccess(true);

        mixpanel.track("Get Loan", {
          distinct_id: `${email}`,
          "loan amount": `${createLoanData?.loan_amount}`,
          "loan name": `${createLoanData?.loan_name}`,
          "loan tenor": `${createLoanData?.loan_tenure}`,
          product_id: `${createLoanData?.product_id}`,
          "Loan status": `successfully applied`,
          "bank statement linked status": `linked`,
        });
      }

      if (handleLoanRequestSuccess) {
        handleLoanRequestSuccess(loanRequestResponse);
        // refetchFetchWalletdetails();
      }
    } catch (error) {
      setError(errorHandler(error));
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: WidgetBankStatementLinkageErrorScreen },
      });

      // Confirm why this line of code was added by Alfred

      // if (createLoanData && setCreateLoanData) {
      //   setCreateLoanData({
      //     ...createLoanData,
      //     customer_bank_account: "",
      //   });
      // }
    }
    setIsLoading(false);
  }

  if (showSuccess) {
    return (
      <SuccessComponent
        title="Request Successful!"
        body={`${successMsg}`}
        handler={() => history.push(ROUTES.widgetDashboard)}
        btnText="Done"
        type="success"
        setSuccess={setSuccess}
      />
    );
  }

  if (isLoading || isSavingProvider) {
    return (
      <>
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={advLogo}
            alt=""
            className="img-fluid"
            style={{
              height: "24px",
              width: "126.53px",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={loader}
            alt=""
            className="img-fluid"
            style={{
              height: "20%",
              width: "50%",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
      </>
    );
  }

  return (
    <div className={styles.stepFour_container}>
      {error && (
        <div>
          <Alert
            message={error}
            type="error"
            showIcon
            closable
            onClose={() => setError!("")}
          />
        </div>
      )}
      {success.bodyText && (
        <div>
          <Alert
            message={success.bodyText}
            type="success"
            showIcon
            closable
            onClose={() => setSuccess!({ ...success, bodyText: "" })}
          />
        </div>
      )}
      <div className={styles.verification_failed}>
        <div className={styles.box}></div>
        <h3>Something went wrong</h3>
        <p>
          We couldn't get your bank statement at the moment. You can try adding
          a different account
        </p>
      </div>
      <div className="d-flex justify-content-end" style={{ marginTop: "20%" }}>
        <button
          className="btn btn-primary btn-radius"
          onClick={handleBankStatementOrCreateLoan}
        >
          Try again
        </button>
        <button
          className="text-primary padding-none ml-3 cursor-pointer"
          onClick={handleAction}
        >
          Continue
        </button>
      </div>
    </div>
  );
}

export default WidgetBankStatementLinkageErrorScreen;
