import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { formatCurrency } from "../../../../../../helpers/formatCurrency";
import { StepProp } from "./LoanRequest";
import { appInsights } from "../../../../../AppInsight/AppInsight";
import styles from "../../Loans.module.scss";

const LoanRequestStepThree = ({
  handleNext,
  handleGoBack,
  processedLoanData,
  setCurrentStep,
  createLoanData,
  loanProducts,
  IsDirectDebit,
  setIsDirectDebit,
  repaymentFrequency,
  existingDirectDebitAccountData,
}: StepProp) => {
  const [viewMore, setViewMore] = useState(false);
  const specificproduct = loanProducts?.find(
    (product) => product.id === createLoanData?.product_id
  );
  // repayment channel id === 6 means direct debit
  if (specificproduct?.repayment_channel_id === 6 && setIsDirectDebit) {
    setIsDirectDebit(true);
  } else if (specificproduct?.repayment_channel_id !== 6 && setIsDirectDebit) {
    setIsDirectDebit(false);
  }
  let location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  useEffect(() => {
    appInsights.trackPageView({
      name: "LoanRequestStepThree.tsx",
      isLoggedIn: true,
    });
  }, []);
  const handleScheduleView = () => {
    setViewMore((prev) => !prev);
  };

  const handleNextPage = () => {
    if (
      IsDirectDebit &&
      existingDirectDebitAccountData?.bank_accounts?.length > 0
    ) {
      handleNext();
    } else if (
      IsDirectDebit &&
      existingDirectDebitAccountData?.bank_accounts?.length === 0
    ) {
      setCurrentStep(6);
    } else {
      setCurrentStep(3);
    }
  };

  return (
    <>
      <h4>Loan Offer</h4>
      <p className="text-muted">
        Based on the selected loan product, you are eligible for this offer.
      </p>

      <div
        className="card"
        style={{ backgroundColor: "#F4F5F6", borderRadius: "10px" }}
      >
        <div
          className="card-header"
          style={{ backgroundColor: "#F4F5F6", borderRadius: "10px" }}
        >
          <h4>{processedLoanData?.product_name}</h4>
        </div>
        <div className="card-body">
          <div className="d-flex justify-content-center">
            <div className="col-md-6">
              <p className="text-muted padding-none">Loan Amount</p>
              <p className="text-dark padding-none font-weight-bolder">
                {`${processedLoanData?.currency.code}${formatCurrency(
                  processedLoanData?.totalPrincipalDisbursed || 0
                )}`}
              </p>
            </div>
            <div className="col-md-6">
              <p className="text-muted padding-none">Loan Tenor</p>
              <p className="text-dark padding-none font-weight-bolder">
                {`${processedLoanData?.loanTermInDays} days`}
              </p>
            </div>
          </div>
          <br />
          <div className="d-flex justify-content-center">
            <div className="col-md-6">
              <p className="text-muted padding-none">Loan Payback</p>
              <p className="text-dark padding-none font-weight-bolder">
                {`${processedLoanData?.currency.code}${formatCurrency(
                  processedLoanData?.totalRepaymentExpected || 0
                )}`}
              </p>
            </div>
            <div className="col-md-6">
              <p className="text-muted padding-none">Loan Interest</p>
              <p className="text-dark padding-none font-weight-bolder">
                {`${processedLoanData?.currency.code}${formatCurrency(
                  processedLoanData?.totalInterestCharged || 0
                )}`}
              </p>
            </div>
          </div>
          <br />
          <div className="d-flex justify-content-center">
            <div className="col-md-6">
              <p className="text-muted padding-none">Repayment Frequency</p>
              <p className="text-dark padding-none font-weight-bolder">
                {repaymentFrequency}
              </p>
            </div>
            <div className="col-md-6">
              <p className="text-muted padding-none"></p>
              <p className="text-dark padding-none font-weight-bolder"></p>
            </div>
          </div>

          <br />

          <div className={styles.loan_schedule}>
            <h4 className={styles.title}>Loan Schedule</h4>

            {viewMore
              ? processedLoanData?.periods?.map(
                  (schedule: any, index: number) => (
                    <div key={index} className={styles.loan_schedule_data}>
                      <span className={styles.serial_number}>{index + 1}</span>
                      <span className={styles.schedule_amount_date}>
                        <strong>
                          ₦{schedule?.totalDueForPeriod.toLocaleString()}
                        </strong>
                        <small>{`${schedule?.dueDate[2]}-${schedule?.dueDate[1]}-${schedule?.dueDate[0]}`}</small>
                      </span>
                    </div>
                  )
                )
              : processedLoanData?.periods
                  .slice(0, 6)
                  .map((schedule: any, index: number) => (
                    <div key={index} className={styles.loan_schedule_data}>
                      <span className={styles.serial_number}>{index + 1}</span>
                      <span className={styles.schedule_amount_date}>
                        <strong>
                          ₦{schedule?.totalDueForPeriod.toLocaleString()}
                        </strong>
                        <small>{`${schedule?.dueDate[2]}-${schedule?.dueDate[1]}-${schedule?.dueDate[0]}`}</small>
                      </span>
                    </div>
                  ))}

            {processedLoanData!.periods?.length > 6 && (
              <div className={styles.view_more_schedule_btn_container}>
                <button
                  className={styles.view_more_schedule_btn}
                  onClick={handleScheduleView}
                >
                  {viewMore ? "View Less" : "View All Schedule"}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-end" style={{ marginTop: "20%" }}>
        <div className="d-flex align-items-center">
          <p
            className="text-primary padding-none mr-3 cursor-pointer"
            onClick={handleGoBack}
          >
            Go Back
          </p>
          <button
            className="btn btn-primary btn-radius"
            onClick={handleNextPage}
          >
            Next
          </button>
        </div>
      </div>
    </>
  );
};

export default LoanRequestStepThree;
