import React, { useEffect, useRef, useState, Dispatch, SetStateAction } from "react";
import styles from "./RegistrationSuccess.module.scss";
import successSVG from "../../../../assets/web/success.svg";
import { getData, postData } from "../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../apis/apiEndpoints";
import { Alert, Button } from "antd";
import { useHistory } from "react-router-dom";
import { encrypt } from "../../../../helpers/encryptor";
import { add } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../interfaces/redux-interface";
import { updateBorrowerDetailsAction } from "../../../../redux/actions";
import { appInsights } from "../../../../components/AppInsight/AppInsight";
import mixpanel from "mixpanel-browser";
import { useLocation } from "react-router-dom";


interface RegistrationSuccessProps {
  error: string;
  setError: Dispatch<SetStateAction<string>>;
  success: string;
  setSuccess: Dispatch<SetStateAction<string>>;
}

function RegistrationSuccess({
  error,
  setError,
  success,
  setSuccess,
}: RegistrationSuccessProps) {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const borrowerData = useSelector(
    (state: ReduxStoreModel) => state.borrowerDetailsReducer
  );

  const isBankStatementConnectedRef = useRef({
    bank_statement_connected: false,
  });

  const history = useHistory();
  const location = useLocation();
  const ipRef = useRef(null);

  useEffect(() => {
    appInsights.trackPageView({
      name: "Web Onboarding  - (RegistrationSuccess.tsx)",
      isLoggedIn: true,
    });
  }, []);

  useEffect(() => {
     fetch('https://api.ipify.org?format=json').then(response => response.json()).then(data => ipRef.current = data.ip)
  }, []);

    const urlExtension = location.pathname.split("/").filter(Boolean);

  // check for first time borrowers
  const checkFirstTimeBorrower = async () => {
    setSuccess("")
    setError("")
    setIsLoading(true);
    try {
      const userExistResponse = await getData(
        `${apiEndpoints.checkCustomerExistsWeb}?identity_number=${borrowerData?.bvn_number}&phone_number=${borrowerData?.borrower_phone}&aggregator_id=${borrowerData?.aggregator_id}&email=${borrowerData?.email}&business_registration_number=${borrowerData?.business_registration_number}`
      );

      if (userExistResponse?.message?.toLowerCase() === "match found") {
        // This is to check if user has connected their bank statement if not a first time borrower
        await checkBankStatementAuthStatus({
          customer_id: userExistResponse.data?.customer_id,
          bank_statement_provider_id:
            userExistResponse.data?.bank_statement_provider_id,
          aggregator_id: borrowerData?.aggregator_id || 0,
        });

        const loginResponse = await postData(apiEndpoints.login, {
          username: borrowerData?.email,
          password: borrowerData?.password,
        });

        const ssoAuthToken = loginResponse?.sso_auth_token;
        const encryptedToken = encrypt(ssoAuthToken);
        localStorage.setItem("token", encryptedToken);

        const session_timeout = add(new Date(), {
          seconds: loginResponse?.expiresIn,
        });

        if (loginResponse.status) {
          const userResponse = await getData(
            apiEndpoints.customerWalletDetails
          );

          const {
            data: {
              bvn,
              country,
              customerType,
              customerId,
              walletId,
              isDefault,
              accountStatus,
              accountNumber,
              accountName,
              bankCode,
              currency,
              isTransactionPinSet,
              transferCharge,
              companyRegistrationNumber,
              aggregatorId,
              isBvnVerified,
              phoneNumberCountryDialCode,
              phoneNumber,
            },
          } = userResponse;

          const encryptedBVN = encrypt(bvn);
          const encryptedWalletId = encrypt(walletId);

          dispatch(
            updateBorrowerDetailsAction({
              accountName,
              bank_account_number: accountNumber,
              accountStatus,
              bank_code: bankCode,
              bank_statement_connected:
                isBankStatementConnectedRef.current.bank_statement_connected,
              bank_statement_provider:
                userExistResponse.data?.bank_statement_provider,
              bank_statement_provider_id:
                userExistResponse.data?.bank_statement_provider_id,
              bvn_number: encryptedBVN,
              currency,
              customer_id: customerId,
              customer_type: customerType,
              isDefault,
              wallet_id: encryptedWalletId,
              firstTimeLogin: loginResponse?.firstTimeLogin,
              transferCharge,
              isTransactionPinSet,
              session_timeout,
              country,
            })
          );

          localStorage.setItem(
            "currentUser",
            JSON.stringify({
              first_name: loginResponse?.first_name,
              last_name: loginResponse?.last_name,
              email: loginResponse?.email,
              customerId,
              isBvnVerified,
              isEmailVerified: loginResponse?.isEmailVerified,
              walletId: encryptedWalletId,
              session_timeout,
              country,
              currency,
              phoneNumberCountryDialCode,
              bvn: encryptedBVN,
              phoneNumber,
              isDefault,
              accountStatus,
              customerType,
              aggregatorId,
              isTransactionPinSet,
              transferCharge,
              business_registration_number:
                userExistResponse.data?.business_registration_number ??
                companyRegistrationNumber,
              company_name: userExistResponse.data?.company_name,
              bank_statement_provider:
                userExistResponse.data?.bank_statement_provider,
              bank_statement_provider_id:
                userExistResponse.data?.bank_statement_provider_id,
              bank_statement_connected:
                isBankStatementConnectedRef.current.bank_statement_connected,
              aggregator_name: borrowerData?.aggregator_name,
            })
          );

          localStorage.setItem(
            "firstTimeLogin",
            JSON.stringify(loginResponse?.firstTimeLogin)
          );

          mixpanel.track('Sign Up', {
              'date created': new Date().toLocaleString(),
              'ip': `${ipRef.current}`,
              'distinct_id': `${customerId}`,
              'URL': window.location.href,
              'aggregator': `${urlExtension[0]}`,
              'product': 'embed web',
          })

          mixpanel.identify(`${customerId}`)

          mixpanel.people.set({ '$name': `${loginResponse?.first_name} ${loginResponse?.last_name}`,
              '$email': `${loginResponse?.email}`,
              'phone' : `${phoneNumber}`,
              'customer id': `${customerId}`,
              'aggregator': `${urlExtension[0]}`,
              'product': 'embed web',
              'customer_type': `${customerType}`,
              'date created': new Date().toLocaleString(),
          });

          history.push(`/${borrowerData?.aggregator_name}`);
        }
      } else {
        setError(userExistResponse?.message);
      }
    } catch (error) {
      setError(error.response?.data?.message ?? error.message);
    } finally {
      setIsLoading(false);
    }
  };

  async function checkBankStatementAuthStatus(data: {
    customer_id: number;
    aggregator_id: number;
    bank_statement_provider_id: number;
  }) {
    try {
      const res = await getData(
        `${apiEndpoints.checkBankStatementProviderIsLinked}?aggregator_id=${data.aggregator_id}&bank_statement_provider_id=${data.bank_statement_provider_id}&customer_id=${data.customer_id}`
      );
      isBankStatementConnectedRef.current.bank_statement_connected =
        res?.status;
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      {error && (
        <div
          style={{ width: "100%", paddingTop: "20px", paddingBottom: "20px" }}
        >
          <Alert
            message={error}
            type="error"
            showIcon
            closable
            onClose={() => setError("")}
          />
        </div>
      )}
      {success && (
        <div
          style={{ width: "100%", paddingTop: "20px", paddingBottom: "20px" }}
        >
          <Alert
            message={success}
            type="success"
            showIcon
            closable
            onClose={() => setSuccess("")}
          />
        </div>
      )}
        <div className={styles.registration_success_container}>
            <div className={styles.registration_success_header}>
                <img
                    src={successSVG}
                    alt=""
                />


                <h3 className="">Verification Successful!</h3>
            </div>
            <p className="">
                Your verification was successful. Your registration is complete.
            </p>

            <Button type='primary' onClick={checkFirstTimeBorrower} disabled={isLoading}>
                {isLoading ? "Please wait..." : "Go to your Dashboard"}
            </Button>

        </div>
    </>
  );
}

export default RegistrationSuccess;
