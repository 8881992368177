import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { IoMdArrowRoundBack } from "react-icons/io";
import LoanRequestStepOne from "./LoanRequestStepOne";
import LoanRequestStepTwo from "./LoanRequestStepTwo";
import LoanRequestStepThree from "./LoanRequestStepThree";
import LoanRequestStepFour from "./LoanRequestStepFour";
import { SuccessComponent } from "../../../SuccessComponent";
import { ROUTES } from "../../../../../../helpers/ROUTES";
import { getDataWithKey } from "../../../../../../apis/apiMethods";
import { apiEndpoints } from "../../../../../../apis/apiEndpoints";
import {
  errorHandler,
  errorToast,
} from "../../../../../../helpers/errorHandler";
import { appInsights } from "../../../../../AppInsight/AppInsight";
import CONFIG from "../../../../../../helpers/config";
import moment from "moment";

import LoanRequestDirectDebit from "./LoanRequestDirectDebit";
import useFetchWalletDetails from "../../../../../../custom-hooks/useFetchWallet";
import { sendResponseToClient } from "../../../../../../helpers";
import { WIDGET_RESPONSE_LOAN_STATUSES } from "../../../../utils";
import useDirectDebitExistingAccounts from "../../../../../../custom-hooks/useDirectDebitExistingAccounts";
import WidgetBankStatementLinkageErrorScreen from "./WidgetBankStatementLinkageErrorScreen";
import WidgetAlternativeLoanCompleteScreen from "./WidgetAlternativeLoanCompleteScreen";
import DirectDebit from "../../../../DirectDebit/DirectDebit";
import { ISuccess } from "../../../../../../interfaces/success";
import { IMonoWidgetData } from "../../../../../../interfaces/monoWidgetData";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../../../interfaces/redux-interface";
import advLogo from "../../../../../../assets/advancly-logo-full.png";
import loader from "../../../../../../assets/loadinggif.gif";
import ResidentialInformation from "./ResidentialInformation";
import { useFetchCustomerResidentialInfoStatusWidget } from "../../../../../../custom-hooks/useFetchCustomerResidentialInfoStatus";

export type CreateLoanData = {
  customer_id: number | undefined;
  bvn_number: string | undefined;
  product_id: number;
  loan_amount: number;
  loan_tenure: number;
  aggregator_id: number | undefined;
  repayment_start_date: string;
  bvn_checker: any;
  annual_interest_rate: number;
  aggregator_loan_ref: string;
  loan_name?: string;
  business_registration_number?: string;
  phone_number?: string; // borrower_phone
  country_code?: string; // borrower_phone
  customer_bank_account?: any;
  is_bankstatement_linkage_failed?: boolean;
  loan_tenor_input_type?: string;
};

export type StepProp = {
  handleNext: () => void;
  handleGoBack: () => void;
  walletDetails?: any | null;
  loanProducts?: LoanProduct[] | [];
  setLoanData?: Dispatch<SetStateAction<LoanDataProp>>;
  setProcessedLoanData?: Dispatch<SetStateAction<ProcessedLoan | undefined>>;
  loanData?: any;
  processedLoanData?: ProcessedLoan;
  encryptedKey?: string;
  currency?: string;
  createLoanData?: CreateLoanData;
  setCurrentStep?: any;
  setCreateLoanData?: (a: CreateLoanData) => void;
  IsDirectDebit?: any;
  setIsDirectDebit?: Dispatch<SetStateAction<Boolean>>;
  existingDirectDebitAccountData?: any;
  isLoanParentLoading?: boolean;
  handleLoanRequestSuccess?: (loanRequestResponse: any) => void;
  refetchFetchWalletdetails?: () => void;
  repaymentFrequency?: string;
  setRepaymentFrequency?: Dispatch<SetStateAction<string>>;
  error?: string;
  setError?: Dispatch<SetStateAction<string>>;
};

export type TenorProps = {
  tenorValue: number;
  tenor: string;
};

export type LoanProduct = {
  id: number;
  product_name: string;
  maximum_tenor: number;
  maximum_amount: number;
  interest_rate: number;
  aggregator_id: number;
  repayment_channel_id: number;
  repayment_channel: string;
  repayment_type: string;
  tenors: TenorProps[];
};

export type ProcessedLoan = {
  currency: { code: "" };
  totalPrincipalDisbursed: number;
  aggregator_loan_ref: string;
  batch_loan_number: null;
  totalInterestCharged: number;
  loanTermInDays: number;
  totalRepaymentExpected: number;
  loan_application_referenceno: string;
  repayment_cycle: string;
  message: string;
  product_name: string;
  periods: any;
};

export type LoanDataProp = {
  productId: number;
  loanTenor: number;
  principalAmount: number;
  interest: number;
  loanEffectiveDate: string;
  aggregator_id: number;
};

const LoanRequest = () => {
  //comeback to this
  // updateBankStatementWidgetStatus,
  const widgetState = useSelector(
    (store: ReduxStoreModel) => store.widgetSettingsReducer,
  );
  const borrowerDetails = useSelector(
    (store: ReduxStoreModel) => store.borrowerDetailsReducer,
  );
  const {
    customer_id,
    aggregator_id,
    first_name,
    last_name,
    email,
    currency,
    bank_statement_connected,
    bank_statement_provider,
    bvn_number,
    business_registration_number,
    borrower_phone,
    country_code,
  } = borrowerDetails;
  const { use_wallet, bankList, encryptedKey } = widgetState;
  const history = useHistory();
  const [IsDirectDebit, setIsDirectDebit] = useState<Boolean>(false);
  const [isLoanParentLoading, setIsLoanParentLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);
  const [loanProducts, setLoanProducts] = useState<LoanProduct[] | []>([]);
  const [walletDetails, setWalletDetails] = useState<any | null>();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState<ISuccess>({
    headerText: "",
    bodyText: "",
  });
  const [loanData, setLoanData] = useState<LoanDataProp>({
    productId: 0,
    principalAmount: 0,
    loanTenor: 0,
    loanEffectiveDate: "",
    interest: 0,
    aggregator_id: 0,
  });
  const [repaymentFrequency, setRepaymentFrequency] = useState<string>("");
  const [processedLoanData, setProcessedLoanData] = useState<ProcessedLoan>();
  const [monoWidgetStateData, setMonoWidgetStateData] =
    useState<IMonoWidgetData | null>(null);
  const [createLoanData, setCreateLoanData] = useState<CreateLoanData>({
    customer_id: customer_id,
    loan_amount: 0,
    loan_name: "",
    product_id: 0,
    loan_tenure: 0,
    aggregator_id: aggregator_id,
    repayment_start_date: moment().format("DD/MM/YYYY"),
    bvn_checker: CONFIG.BVN_CHECKER,
    annual_interest_rate: 0,
    aggregator_loan_ref: Date.now().toString(),
    bvn_number: bvn_number,
    business_registration_number: business_registration_number,
    phone_number: borrower_phone,
    country_code: country_code,
    customer_bank_account: "",
  });

  const { refetch: refetchFetchWalletdetails } = useFetchWalletDetails({
    borrowerId: customer_id,
    aggregatorId: aggregator_id,
  });

  const handleLoanRequestSuccess = (loanRequestResponse: any) => {
    sendResponseToClient({
      status: WIDGET_RESPONSE_LOAN_STATUSES.loan_request,
      data: { message: loanRequestResponse },
    });
  };

  useEffect(() => {
    appInsights.trackPageView({ name: "LoanRequest.tsx", isLoggedIn: true });
  }, []);

  useEffect(() => {
    if (aggregator_id) {
      setLoanData({
        ...loanData,
        aggregator_id: aggregator_id,
      });
    }
  }, [aggregator_id]);

  async function fetchWallet() {
    setIsLoanParentLoading(true);
    try {
      const response = await getDataWithKey(
        `${apiEndpoints.getWallet}?borrower_id=${customer_id}&aggregator_id=${aggregator_id}`,
      );
      let countryCode =
        response.data.currency === "NGN"
          ? "NG"
          : response.data.currency === "KES"
            ? "KE"
            : "";
      getAllLoanProducts(Number(aggregator_id), countryCode);
      setWalletDetails(response.data);
    } catch (error) {
      errorToast(errorHandler(error));
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: LoanRequest },
      });
    } finally {
      setIsLoanParentLoading(false);
    }
  }

  const getAllLoanProducts = async (
    aggregatorId: number,
    countryCode: string,
  ) => {
    setIsLoanParentLoading(true);
    try {
      const response = await getDataWithKey(
        `${apiEndpoints.getLoanProducts}?aggregator_id=${aggregatorId}&country_code=${countryCode}&aggregator_domain=${window.location.origin}`,
      );
      setLoanProducts(response.data);
    } catch (error) {
      errorToast(errorHandler(error));
      appInsights.trackException({
        //@ts-ignore
        exception: error,
        properties: { fileName: LoanRequest },
      });
    } finally {
      setIsLoanParentLoading(false);
    }
  };

  useEffect(() => {
    if (customer_id && aggregator_id) {
      fetchWallet();
    }
    //eslint-disable-next-line
  }, [customer_id, aggregator_id]);

  const handleNext = () => {
    if (currentStep < 6) {
      setCurrentStep(currentStep + 1);
    } else {
      setIsLoanParentLoading(true);
      setTimeout(() => {
        setIsLoanParentLoading(false);
        setShowSuccess(true);
      }, 2000);
    }
  };

  const handleGoBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    } else {
      history.push(ROUTES.widgetLoan);
    }
  };

  const {
    data: existingDirectDebitAccountData,
    isLoading: directDebitLoading,
    error: directdebitError,
  } = useDirectDebitExistingAccounts({
    customerId: walletDetails?.customerId ?? 0,
  });

  const steps =
    currency === "NGN" && use_wallet && walletDetails?.accountNumber
      ? [
          // <LoanRequestStepOne
          //   walletDetails={walletDetails}
          //   handleGoBack={handleGoBack}
          //   handleNext={handleNext}
          //   currency={currency}
          // />,
          <LoanRequestStepTwo
            loanProducts={loanProducts}
            handleGoBack={handleGoBack}
            handleNext={handleNext}
            loanData={loanData}
            setLoanData={setLoanData}
            encryptedKey={encryptedKey}
            setProcessedLoanData={setProcessedLoanData}
            setCreateLoanData={setCreateLoanData}
            createLoanData={createLoanData}
            isLoanParentLoading={isLoanParentLoading}
            setRepaymentFrequency={setRepaymentFrequency}
            error={error}
            setError={setError}
          />,
          <LoanRequestStepThree
            loanProducts={loanProducts}
            processedLoanData={processedLoanData}
            createLoanData={createLoanData}
            handleGoBack={handleGoBack}
            handleNext={handleNext}
            setCurrentStep={setCurrentStep}
            IsDirectDebit={IsDirectDebit}
            setIsDirectDebit={setIsDirectDebit}
            existingDirectDebitAccountData={existingDirectDebitAccountData}
            repaymentFrequency={repaymentFrequency}
            setRepaymentFrequency={setRepaymentFrequency}
          />,
          <LoanRequestDirectDebit
            setCreateLoanData={setCreateLoanData}
            createLoanData={createLoanData}
            handleGoBack={handleGoBack}
            handleNext={handleNext}
            existingDirectDebitAccountData={existingDirectDebitAccountData}
            directDebitLoading={directDebitLoading}
            directdebitError={directdebitError}
            setError={setError}
            error={error}
            setCurrentStep={setCurrentStep}
            customer_id={customer_id}
            aggregator_id={aggregator_id}
          />,

          <ResidentialInformation
            setError={setError}
            error={error}
            aggregator_id={aggregator_id}
            customer_id={customer_id}
            setCurrentStep={setCurrentStep}
            handleNext={handleNext}
          />,

          <LoanRequestStepFour
            encryptedKey={encryptedKey}
            createLoanData={createLoanData}
            setCreateLoanData={setCreateLoanData}
            handleGoBack={() => setCurrentStep(0)}
            handleNext={handleNext}
            walletDetails={walletDetails}
            loanData={loanData}
            setCurrentStep={setCurrentStep}
            handleLoanRequestSuccess={handleLoanRequestSuccess}
            bank_statement_provider={bank_statement_provider}
            bank_statement_connected={bank_statement_connected}
            first_name={first_name}
            last_name={last_name}
            email={email}
            error={error}
            setError={setError}
            setMonoWidgetStateData={setMonoWidgetStateData}
            monoWidgetStateData={monoWidgetStateData}
          />,

          <WidgetBankStatementLinkageErrorScreen
            handleNext={handleNext}
            encryptedKey={encryptedKey}
            createLoanData={createLoanData}
            setCreateLoanData={setCreateLoanData}
            walletDetails={walletDetails}
            loanData={loanData}
            setCurrentStep={setCurrentStep}
            handleLoanRequestSuccess={handleLoanRequestSuccess}
            bank_statement_provider={bank_statement_provider}
            bank_statement_connected={bank_statement_connected}
            first_name={first_name}
            last_name={last_name}
            email={email}
            error={error}
            setError={setError}
            setSuccess={setSuccess}
            success={success}
            monoWidgetStateData={monoWidgetStateData}
            setMonoWidgetStateData={setMonoWidgetStateData}
          />,
          <WidgetAlternativeLoanCompleteScreen
            createLoanData={createLoanData}
            handleLoanRequestSuccess={handleLoanRequestSuccess}
            setCreateLoanData={setCreateLoanData}
            encryptedKey={encryptedKey}
            handleGoBack={() => setCurrentStep(0)}
            error={error}
            email={email}
            setError={setError}
            monoWidgetStateData={monoWidgetStateData}
          />,
          <DirectDebit
            customerId={walletDetails?.customerId}
            bankList={bankList}
            setError={setError}
            error={error}
            setSuccess={setSuccess}
            success={success}
            setCurrentStep={setCurrentStep}
          />,
        ]
      : [
          <LoanRequestStepTwo
            loanProducts={loanProducts}
            handleGoBack={handleGoBack}
            handleNext={handleNext}
            loanData={loanData}
            setLoanData={setLoanData}
            encryptedKey={encryptedKey}
            setProcessedLoanData={setProcessedLoanData}
            setCreateLoanData={setCreateLoanData}
            createLoanData={createLoanData}
          />,
          <LoanRequestStepThree
            processedLoanData={processedLoanData}
            handleGoBack={handleGoBack}
            handleNext={handleNext}
          />,

          <LoanRequestStepFour
            encryptedKey={encryptedKey}
            aggregatorId={aggregator_id}
            createLoanData={createLoanData}
            handleGoBack={() => setCurrentStep(0)}
            handleNext={handleNext}
            walletDetails={walletDetails}
            loanData={loanData}
            handleLoanRequestSuccess={handleLoanRequestSuccess}
            refetchFetchWalletdetails={refetchFetchWalletdetails}
          />,
        ];

  if (showSuccess) {
    return (
      <SuccessComponent
        title="Request Successful!"
        body="Your request has been received and will be reviewed for approval"
        handler={() => history.push(ROUTES.widgetDashboard)}
        btnText="Done"
        type="success"
      />
    );
  }
  if (isLoanParentLoading) {
    return (
      <>
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={advLogo}
            alt=""
            className="img-fluid"
            style={{
              height: "24px",
              width: "126.53px",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
        <div className="d-flex justify-content-center">
          <img
            src={loader}
            alt=""
            className="img-fluid"
            style={{
              height: "20%",
              width: "50%",
              objectFit: "contain",
            }}
          />
        </div>
        <br />
        <br />
      </>
    );
  }
  return (
    <div>
      <div
        onClick={() =>
          currentStep < 1
            ? history.push(ROUTES.widgetLoan)
            : currentStep === 2
              ? setCurrentStep(currentStep - 1)
              : currentStep === 1
                ? setCurrentStep(currentStep - 1)
                : currentStep === 3
                  ? setCurrentStep(2)
                  : currentStep === 5
                    ? setCurrentStep(currentStep - 1)
                    : currentStep === 4
                      ? setCurrentStep(1)
                      : currentStep === 7 &&
                          existingDirectDebitAccountData?.bank_accounts
                            ?.length > 0
                        ? setCurrentStep(2)
                        : currentStep === 7 &&
                            existingDirectDebitAccountData?.bank_accounts
                              ?.length === 0
                          ? setCurrentStep(1)
                          : history.push(ROUTES.widgetLoan)
        }
        className="cursor"
      >
        <IoMdArrowRoundBack className="cursor__back" />
      </div>
      <h4>{currentStep < 1 ? "Loan request" : null}</h4>
      {steps[currentStep]}
    </div>
  );
};

export default LoanRequest;
