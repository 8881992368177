import { Alert, Form, Select } from "antd";
import React, {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import plus from "../../../../../../assets/plus.svg";
import { CreateLoanData } from "./LoanRequest";
import Loader from "../../../../../Loader/Loader";
import { useFetchCustomerResidentialInfoStatusWidget } from "../../../../../../custom-hooks/useFetchCustomerResidentialInfoStatus";

interface ILoanRequestdirectDebit {
  handleNext: () => void;
  handleGoBack: () => void;
  createLoanData?: CreateLoanData;
  setCreateLoanData?: (a: CreateLoanData) => void;
  existingDirectDebitAccountData?: any;
  directDebitLoading?: boolean;
  directdebitError?: any;
  error?: string;
  setError?: Dispatch<SetStateAction<string>>;
  setCurrentStep?: Dispatch<SetStateAction<number>>;
  customer_id: number;
  aggregator_id: number;
}

const LoanRequestDirectDebit = ({
  handleNext,
  handleGoBack,
  setCreateLoanData,
  createLoanData,
  existingDirectDebitAccountData,
  directDebitLoading,
  directdebitError,
  error,
  setError,
  setCurrentStep,
  customer_id,
  aggregator_id,
}: ILoanRequestdirectDebit) => {
  const [form] = Form.useForm();
  const [accountDetails, setAccountDetails] = useState<number | string>("");
  const [bankDetails, setBankDetails] = useState({
    account_name: "",
    account_number: "",
    bank_name: "",
  });

  const {
    data: residentialInfoStatus,
    isLoading: isLoadingResidentialInfoStatus,
  } = useFetchCustomerResidentialInfoStatusWidget({
    customer_id: customer_id,
    aggregator_id: aggregator_id,
  });

  useEffect(() => {
    if (existingDirectDebitAccountData?.bank_accounts.length === 1) {
      const { account_name, account_number, bank_name } =
        existingDirectDebitAccountData?.bank_accounts[0];
      setBankDetails({
        ...bankDetails,
        account_name: account_name,
        account_number: account_number,
        bank_name: bank_name,
      });

      setAccountDetails(account_number);
    }
  }, [directDebitLoading]);

  const handleselectedDebitAccount = (val: number) => {
    setAccountDetails(val);
  };

  const handleNextFn = () => {
    if (createLoanData && setCreateLoanData) {
      setCreateLoanData({
        ...createLoanData,
        customer_bank_account: accountDetails,
      });
      setError!("");
      if (residentialInfoStatus) {
        setCurrentStep!(4);
      } else {
        handleNext();
      }
    }
  };

  return (
    <>
      {error && (
        <div style={{ width: "100%", paddingTop: "50px" }}>
          <Alert
            message={error}
            type="error"
            showIcon
            closable
            onClose={() => setError!("")}
          />
        </div>
      )}
      <h4>Direct Debit Account</h4>
      <Fragment>
        <p>
          Select an account you want us to debit for <br /> your repayment
        </p>
        {directDebitLoading ? (
          <div className="d-flex justify-content-center align-items-center">
            <Loader />
          </div>
        ) : existingDirectDebitAccountData?.bank_accounts?.length > 1 ? (
          <Form layout="vertical" form={form}>
            <Form.Item
              className="mb-3 mb-md-0 mt-2"
              name="productId"
              label="Direct Debit Account"
              rules={[{ required: true, message: "Required field" }]}
            >
              <Select
                placeholder="Select Direct Debit Account"
                allowClear
                onChange={(value) => {
                  handleselectedDebitAccount(value);
                }}
              >
                {existingDirectDebitAccountData?.bank_accounts?.map(
                  ({
                    account_name,
                    account_number,
                    bank_name,
                  }: {
                    account_name: string;
                    account_number: string;
                    bank_name: any;
                  }) => (
                    <Select.Option value={account_number} key={account_number}>
                      {account_name}
                      {`  - ${bank_name}  ${account_number}`}
                    </Select.Option>
                  ),
                )}
              </Select>
            </Form.Item>
          </Form>
        ) : existingDirectDebitAccountData?.bank_accounts?.length === 1 ? (
          <div className="mb-4-embed">
            <label>Direct Debit Account</label>
            <input
              type="text"
              className="form-control-embed"
              name="directdebitAccount"
              value={`${bankDetails.account_name} - ${bankDetails.bank_name}  ${bankDetails.account_number}`}
              readOnly={
                existingDirectDebitAccountData?.bank_accounts?.length === 1
              }
            />
          </div>
        ) : null}
        {directdebitError && setError!(`${directdebitError}`)}
        <p
          className="cursor-pointer"
          role="button"
          aria-roledescription="button"
          onClick={() => {
            setCurrentStep!(7);
          }}
        >
          {" "}
          <img src={plus} className="" alt="" /> {""}
          Add a new account
        </p>
      </Fragment>
      <div className="d-flex justify-content-end" style={{ marginTop: "20%" }}>
        <div className="d-flex align-items-center">
          <p
            role="button"
            aria-roledescription="button"
            className="text-primary padding-none mr-3 cursor-pointer"
            onClick={handleGoBack}
          >
            Go Back
          </p>
          <button
            className="btn btn-primary btn-radius"
            onClick={handleNextFn}
            disabled={!accountDetails || isLoadingResidentialInfoStatus}
          >
            {isLoadingResidentialInfoStatus ? "Please wait..." : "Next"}
          </button>
        </div>
      </div>
    </>
  );
};

export default LoanRequestDirectDebit;
