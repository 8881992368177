import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { BiRightArrowAlt } from "react-icons/bi";
import { IoMdArrowRoundBack } from "react-icons/io";
import { ROUTES } from "../../../../helpers/ROUTES";
import user from "../../../../assets/loans/user.svg";
import bag from "../../../../assets/loans/bag.svg";
import infoCircle from "../../../../assets/web/info-circle-loan.svg";
import styles from "../../WidgetFlow.module.scss";
import { deviceType } from "react-device-detect";
import { appInsights } from "../../../AppInsight/AppInsight";
import loader from "../../../../assets/loadinggif.gif";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../interfaces/redux-interface";
import useBankStatementProvider from "../../../../custom-hooks/useBankStatementProvider";
import useFetchBankStatementProviderStatus from "../../../../custom-hooks/useFetchBankStatementProviderLinked";
import useFetchUserExists from "../../../../custom-hooks/useFetchUserExists";
import mixpanel from "mixpanel-browser";
import advLogo from "../../../../assets/advancly-logo-full.png";
import redDot from "../../../../assets/red-dot.svg";
import { useFetchPendingWidgetLoanOffer } from "../../../../custom-hooks/useFetchPendingLoanOffer";

const LoansComponent = () => {
  const history = useHistory();
  const borrowerDetails = useSelector(
    (state: ReduxStoreModel) => state.borrowerDetailsReducer,
  );

  const { data: userExistsData } = useFetchUserExists({
    identity_number: borrowerDetails?.bvn_number,
    phone_number: borrowerDetails?.borrower_phone,
    aggregator_id: borrowerDetails?.aggregator_id,
    email: borrowerDetails?.email,
    business_registration_number: borrowerDetails?.business_registration_number,
  });

  const { data: bankStatementLinkedStatus } =
    useFetchBankStatementProviderStatus({
      aggregator_id: borrowerDetails?.aggregator_id,
      customer_id: borrowerDetails?.customer_id,
      bank_statement_provider_id:
        borrowerDetails?.bank_statement_provider_id ??
        userExistsData?.bank_statement_provider_id,
    });

  const { data: pendingLoanOffer } = useFetchPendingWidgetLoanOffer({
    customer_id: borrowerDetails?.customer_id,
    aggregator_id: borrowerDetails?.aggregator_id,
  });

  const {
    handleLaunchOkraWidget,
    monoConnect,
    accountLinkSuccessRef,
    monoWidgetData,
    isSavingProvider,
  } = useBankStatementProvider({ borrowerDetails, bankStatementLinkedStatus });

  useEffect(() => {
    appInsights.trackPageView({
      name: "Loans.component.tsx",
      isLoggedIn: true,
    });

    mixpanel.track_pageview({ page: "Loan - Widget" });
  }, []);

  const handleBankStatement = () => {
    if (!borrowerDetails?.bank_statement_connected) {
      if (borrowerDetails?.bank_statement_provider?.toLowerCase() === "okra") {
        handleLaunchOkraWidget();
      } else {
        monoConnect();
      }
    }
  };

  return (
    <div>
      <div
        onClick={() => history.push(ROUTES.widgetDashboard)}
        className="cursor"
      >
        <IoMdArrowRoundBack className="cursor__back" />
      </div>
      <h4>Loans</h4>
      <br />

      {isSavingProvider ? (
        <>
          <br />
          <div className="d-flex justify-content-center">
            <img
              src={advLogo}
              alt=""
              className="img-fluid"
              style={{
                height: "24px",
                width: "126.53px",
                objectFit: "contain",
              }}
            />
          </div>
          <br />
          <br />
          <div className="d-flex justify-content-center">
            <img
              src={loader}
              alt=""
              className="img-fluid"
              style={{
                height: "20%",
                width: "50%",
                objectFit: "contain",
              }}
            />
          </div>
          <br />
          <br />
        </>
      ) : !borrowerDetails?.bank_statement_connected ? (
        <div className={styles.linkBankstatement}>
          <img src={infoCircle} alt="" />
          <span>
            Link your bank statement to improve your credit eligibility
          </span>
          <button onClick={handleBankStatement}>Proceed</button>
        </div>
      ) : null}

      <div
        className="card cursor bg-primary"
        style={{ height: "108px" }}
        onClick={() => history.push(ROUTES.widgetLoanRequest)}
      >
        <div className="card-body d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <div
              className={`${
                deviceType === "mobile" ? styles.iconMobile : styles.icon
              } icon__primary mr-3`}
              style={{ borderRadius: "10px" }}
            >
              <img
                src={user}
                alt=""
                className="img-fluid"
                style={{ width: "11.48px", height: "13.2px" }}
              />
            </div>
            <div>
              <p className="font-weight-bold text-white padding-none">
                Request for new loan
              </p>
              <small className="text-white padding-none">
                Get a new loan offer
              </small>
            </div>
          </div>

          <BiRightArrowAlt className="text-white" size="20px" />
        </div>
      </div>
      <br />

      <div
        className="card cursor"
        style={{ height: "108px" }}
        onClick={() => history.push(ROUTES.widgetLoanHistory)}
      >
        <div className="card-body d-flex justify-content-between align-items-center">
          <div
            className="d-flex align-items-center"
            style={{ position: "relative" }}
          >
            <div
              className={`${
                deviceType === "mobile" ? styles.iconMobile : styles.icon
              } icon__primary mr-3`}
              style={{ backgroundColor: "#9DA9B3", borderRadius: "10px" }}
            >
              <img
                src={bag}
                alt=""
                className="img-fluid"
                style={{ width: "11.48px", height: "13.2px" }}
              />
            </div>
            <div>
              <p className="font-weight-bold text-primary padding-none">
                Loan History
              </p>
              <small className=" padding-none">See your loan offers</small>
            </div>
            {pendingLoanOffer?.length > 0 && (
              <img src={redDot} alt="" className={styles.red_dot} />
            )}
          </div>

          <BiRightArrowAlt className="" size="20px" />
        </div>
      </div>
      <br />
      <br />
    </div>
  );
};

export default LoansComponent;
