import axios from "axios";
import CONFIG from "../helpers/config";
import { appInsights } from "../components/AppInsight/AppInsight";
import { getHTTPKey, getToken } from "../helpers/appHelpers";
import { decrypt } from "../helpers/encryptor";

export const token = () => {
  // const token = localStorage.getItem("token");
  const token = getToken();
  if (!token) {
    return null;
  }
  return decrypt(token);
};

const baseURL = CONFIG.BASE_URL2;
const baseURL2 = CONFIG.BASE_URL2;
// const baseURL = "http://c27b6edca1d7.ngrok.io/api/v1";
let savedHeaders = {};

const axiosInstance = axios.create({
  baseURL,
});

// Request Interceptor
axiosInstance.interceptors.request.use(
  function (config) {
    config.headers = {
      ...config.headers,
      "Content-Type": "application/json",
      "Content-Security-Policy":
        "default-src https: 'unsafe-eval' 'unsafe-inline'; object-src 'none'",
      Authorization: `Bearer ${token()}`,
    };
    // you can also do other modification in config
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Response Interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    appInsights.trackException({ exception: error });
    if (error.response && error.response.status === 500) {
      return Promise.reject(new Error("Internal Server Error"));
    }
    return Promise.reject(error);
  }
);

const resetPasswordInitialAxiosInstance = axios.create({
  baseURL,
})

resetPasswordInitialAxiosInstance.interceptors.request.use(
    function (config) {
      config.headers = {
        ...config.headers,
        "Content-Type": "application/json",
        "Content-Security-Policy":
            "default-src https: 'unsafe-eval' 'unsafe-inline'; object-src 'none'",
        Authorization: `Bearer ${token()}`,
      };
      // you can also do other modification in config
      return config;
    },
    function (error) {
      if(error.response && error.response.status === 500) {
        return Promise.reject(new Error("Internal Server Error"))
      }
      return Promise.reject(error);
    }
);

resetPasswordInitialAxiosInstance.interceptors.response.use(
    function (response) {

      savedHeaders = {
        ssoarraffinity: response.headers["ssoarraffinity"],
        ssoarraffinitysamesite: response.headers["ssoarraffinitysamesite"]
      };

      localStorage.setItem('savedHeaders', JSON.stringify(savedHeaders));

      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
);

export const resetPasswordInitialStepPostData = async (url, reqBody) => {
  const data = await resetPasswordInitialAxiosInstance({
    method: "POST",
    url,
    data: reqBody,
  });
  return data.data;
};

const resetPasswordFinalStepAxiosInstance = axios.create({
  baseURL,
})

resetPasswordFinalStepAxiosInstance.interceptors.request.use(
    function (config) {
      const savedHeaders = JSON.parse(localStorage.getItem('savedHeaders')) || {};
      config.headers = {
        ...config.headers,
        ...savedHeaders,
        "Content-Type": "application/json",
        "Content-Security-Policy":
            "default-src https: 'unsafe-eval' 'unsafe-inline'; object-src 'none'",
        Authorization: `Bearer ${token()}`,
      };

      // you can also do other modification in config
      return config;
    },
    function (error) {
      if(error.response && error.response.status === 500) {
        return Promise.reject(new Error("Internal Server Error"))
      }
      return Promise.reject(error);
    }
);

export const resetPasswordFinalStepPostData = async (url, reqBody) => {
  const data = await resetPasswordFinalStepAxiosInstance({
    method: "POST",
    url,
    data: reqBody,
  });
  return data.data;
};

const axiosInstance2 = axios.create({
  baseURL: baseURL2,
});

axiosInstance2.interceptors.request.use(
  function (config) {
    config.headers = {
      ...config.headers,
      "Content-Type": "application/json",
      "Content-Security-Policy":
        "default-src https: 'unsafe-eval' 'unsafe-inline'; object-src 'none'",
      Authorization: `Bearer ${token()}`,
    };
    // you can also do other modification in config
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance2.interceptors.response.use(undefined, async function (error) {
  appInsights.trackException({ exception: error });
  if (error.response && error.response.status === 500) {
    return Promise.reject(new Error("Internal Server Error"));
  }
  return Promise.reject(error);
});

export const getData = async (url) => {
  const data = await axiosInstance({
    method: "GET",
    url,
  });
  return data.data;
};

export const getDataWithKey = async (url) => {
  const data = await axiosInstance({
    method: "GET",
    url,
    headers: {
      HTTP_KEY: getHTTPKey(),
    },
  });
  return data.data;
};

export const getDotNetDataWithKey = async (url) => {
  const data = await axiosInstance2({
    method: "GET",
    url,
    headers: {
      HTTP_KEY: getHTTPKey(),
    },
  });
  return data.data;
};

export const postData = async (url, reqBody) => {
  const data = await axiosInstance({
    method: "POST",
    url,
    data: reqBody,
  });
  return data.data;
};

export const postDataWithKey = async (url, reqBody) => {
  const data = await axiosInstance({
    method: "POST",
    url,
    data: reqBody,
    headers: {
      HTTP_KEY: getHTTPKey(),
    },
  });
  return data.data;
};

export const postDotNetDataWithKey = async (url, reqBody) => {
  const data = await axiosInstance2({
    method: "POST",
    url,
    data: reqBody,
    headers: {
      HTTP_KEY: getHTTPKey(),
    },
  });
  return data.data;
};

export const putDotNetDataWithKey = async (url, reqBody) => {
  const data = await axiosInstance2({
    method: "PUT",
    url,
    data: reqBody,
    headers: {
      HTTP_KEY: getHTTPKey(),
    },
  });
  return data.data;
};

export const patchData = async (url, reqBody) => {
  const data = await axiosInstance({
    method: "PATCH",
    url,
    data: reqBody,
  });
  return data.data;
};

export const putData = async (url, reqBody) => {
  const data = await axiosInstance({
    method: "PUT",
    url,
    data: reqBody,
  });
  return data.data;
};

export const deleteData = async (url, reqBody) => {
  const data = await axiosInstance({
    method: "DELETE",
    url,
    data: reqBody,
  });
  return data.data;
};
